import React, { useState } from "react";
import Excel from "exceljs";
import { saveAs } from "file-saver";
import GlobalLoader from "src/components/SuspenseLoader/GlobalLoader";
import { Button, Tooltip } from "@mui/material";

type IProps = {
  excelName: string;
  excelTableHead: string[];
  excelData: any[];
};

const AccountReportExcelGenerator = ({
  excelName,
  excelTableHead,
  excelData,
}: IProps) => {
  const [loading, setLoading] = useState(false);
  const workbook = new Excel.Workbook();

  const saveExcel = async () => {
    setLoading(true);
    const data = [];
    try {
      // creating one worksheet in workbook
      const worksheet = workbook.addWorksheet(`${excelName}`);
      const titleRow = worksheet.addRow(excelTableHead);
      titleRow.eachCell((cell) => {
        cell.font = { bold: true };
      });
      excelData.forEach((rowData) => {
        const values = excelTableHead.map((header) => rowData[header]);
        worksheet.addRow(values);
      });

      const excelDataGenerate = await workbook.xlsx.writeBuffer();
      // download the processed file
      saveAs(new Blob([excelDataGenerate]), `${excelName}.xlsx`);
      setLoading(false);
    } catch (error) {
      setLoading(false);
      console.error("Something Went Wrong", error.message);
    }
  };
  return (
    <>
      {loading ? (
        <GlobalLoader />
      ) : (
        <Tooltip title="Download excel report">
          <Button onClick={saveExcel} variant="outlined" size="small">
            Excel
          </Button>
        </Tooltip>
      )}
    </>
  );
};

export default AccountReportExcelGenerator;
