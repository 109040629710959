import { Box, Typography } from "@mui/material";
import moment from "moment";
import { IDate } from "src/Types/module/serviceRequest/serviceRequestTypes";

type IProps = {
  componentRef: any;
  company: string;
  voucherType: string;
  date: IDate;
};

const PrintVoucherComponents = ({
  componentRef,
  company,
  voucherType,
  date,
}: IProps) => {
  return (
    <Box
      ref={componentRef}
      p={4}
      sx={{ position: "relative", height: "100vh", width: "100%" }}
    >
      <Box className="print-page">
        <Box textAlign={"center"}>
          <Typography variant="h2" gutterBottom>
            {company}
          </Typography>
          <Typography fontSize={13}>
            Sattara Centre (15th Floor), 30/A, Nayapaltan, VIP Road, Dhaka-1000.{" "}
            <br />
            Tel : 9332728 , 8332712 , Email: atab@citech.net, www.atab.org.bd
          </Typography>
          <Typography
            variant="h5"
            fontWeight={"bold"}
            pt={0.5}
          >{`${voucherType} Voucher`}</Typography>
        </Box>
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
            pt: 3,
          }}
        >
          <Box sx={{ display: "flex", alignItems: "center", gap: 3 }}>
            <Typography fontSize={11}>
              Form :{" "}
              {date.startDate && moment(date.startDate).format("DD/MM/YYYY")}
            </Typography>
            <Typography fontSize={11}>
              TO : {date.endDate && moment(date.endDate).format("DD/MM/YYYY")}
            </Typography>
          </Box>
          <Typography fontSize={11}>
            Printing Date: {moment(new Date()).format("DD/MM/YYYY")}
          </Typography>
        </Box>
      </Box>
      <Box pt={1}>
        <table className="member-info-print">
          <thead>
            <tr className="business-license-table-head">
              <th>Voucher No</th>
              <th>Account Head</th>
              <th>Description</th>
              <th>Debit</th>
              <th>Credit</th>
            </tr>
          </thead>
          <tbody>
            {/* {memberInvoice.length ? (
              <>
                {memberInvoice.map((item, index: number) => {
                  return (
                    <tr className="business-license-table-body" key={index}>
                      <td style={{ fontSize: "12px" }}>
                        {moment(item.created_at).format("DD/MM/YYYY")}
                      </td>
                      <td style={{ fontSize: "12px" }}>{item.voucher_no}</td>
                      <td style={{ fontSize: "12px" }}>
                        {item.money_receipt_no}
                      </td>
                      <td
                        style={{
                          width: "365px",
                          fontSize: "13px",
                        }}
                      >
                        {item.description}
                      </td>
                      <td style={{ fontSize: "12px" }}>
                        {item.total_collected_amount}
                      </td>
                    </tr>
                  );
                })}
              </>
            ) : (
              <>
                <tr className="business-license-table-body">
                  <td
                    colSpan={5}
                    style={{ padding: "10px", fontWeight: "bold" }}
                  >
                    No data found
                  </td>
                </tr>
              </>
            )} */}
          </tbody>
        </table>
      </Box>

      {/* <Box
        sx={{
          position: "absolute",
          bottom: 10,
        }}
      > */}
      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
          width: "93%",
          position: "absolute",
          bottom: 10,
        }}
      >
        <Typography fontSize={11}>Developed by M360ICT</Typography>
        <Typography fontSize={11}>Computer generated</Typography>
      </Box>
      {/* </Box> */}
    </Box>
  );
};

export default PrintVoucherComponents;
