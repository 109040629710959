import {
  Box,
  Card,
  Container,
  Grid,
  Button,
  Typography,
  CardContent,
  CardHeader,
  Tooltip,
  IconButton,
} from "@mui/material";
import axios from "axios";
import { useEffect, useRef, useState } from "react";
import { Helmet } from "react-helmet-async";
import { useParams } from "react-router-dom";
import { imgUrl, url } from "src/Helpers/utils/Constant";
import GlobalLoader from "src/components/SuspenseLoader/GlobalLoader";
import { ISingleTraineeApplication } from "src/Utils/type";
import { Link } from "react-router-dom";
import moment from "moment";
import { useAuthContext } from "src/components/Context/AuthContext/AuthContext";
import { getStatusLabel } from "../../../../../../Utils/Combined";
import { useReactToPrint } from "react-to-print";
import PrintIcon from "@mui/icons-material/Print";
import ShortCourseForm from "../components/ShortCourseForm";
import PdfAndImageView from "src/components/pdfAndImageView/PdfAndImageView";
import { CommonTypoGraphy } from "src/components/pdfAndImageView/CommonTypoGraphy";
import ModalsWrapper from "src/components/Modals/ModalsWrapper";
import UpdateSingleTraineeApplication from "./update/UpdateSingleTraineeApplication";
import EditIcon from "@mui/icons-material/Edit";
import { MdAssignmentAdd } from "react-icons/md";
import AssignBatch from "./AssignBatch";

const SingleTraineeApplication = () => {
  const { singleTraineeId } = useParams();
  const [singleTraineeApplication, setSingleTraineeApplication] =
    useState<ISingleTraineeApplication>({} as ISingleTraineeApplication);
  const [loading, setLoading] = useState<Boolean>(false);
  const { user } = useAuthContext();
  const [imageViewOpen, SetImageViewOpen] = useState<boolean>(false);
  const [imageFile, setImageFile] = useState<string | null>("");
  const [open, setOpen] = useState({
    update: false,
    assign: false,
  });
  const [reload, setReload] = useState(false);

  const componentRef = useRef(null);
  const handlePrint = useReactToPrint({
    content: () => componentRef.current,
    documentTitle: "short_course",
    removeAfterPrint: true,
  });
  const handleUpdate = async () => {
    const confirm = window.confirm("Are you sure?");
    if (confirm) {
      setLoading(true);
      try {
        const res = await axios.patch(
          `${url}/api/training/course/application/${singleTraineeApplication.training_course_application_id}?user_member_id=${user.user_admin_id}`,
          {
            training_course_application_status: "rejected",
          }
        );

        if (res.data.success) {
          setLoading(false);
          alert("Application rejected successful");
          setSingleTraineeApplication({
            ...singleTraineeApplication,
            training_course_application_status: "rejected",
          });
        } else {
          setLoading(false);
          alert("Cannot reject application");
        }
      } catch (err) {
        setLoading(false);
        alert("Cannot reject application");
      }
    }
  };

  useEffect(() => {
    const CancelToken = axios.CancelToken;
    const source = CancelToken.source();
    setLoading(true);
    axios
      .get(`${url}/api/training/course/application/${singleTraineeId}`, {
        cancelToken: source.token,
      })
      .then((data) => {
        setSingleTraineeApplication(data.data.data);
        setLoading(false);
      })
      .catch((err) => {
        if (axios.isCancel(err)) {
          setLoading(false);
        } else {
          // handle error
          setLoading(false);
        }
      });
    return () => {
      // cancel the request before component unmounts
      source.cancel();
    };
  }, [singleTraineeId, reload]);

  return (
    <>
      {!loading ? (
        <>
          <Helmet>
            <title>Atab - Single course application information</title>
          </Helmet>
          <Container maxWidth="lg">
            {singleTraineeApplication.training_course_application_id ? (
              <Box pb={2}>
                <Card sx={{ mb: 2 }}>
                  <Box
                    sx={{
                      display: "flex",
                      justifyContent: "space-between",
                      p: 1,
                    }}
                  >
                    <Box>
                      <Box display="flex">
                        <Typography
                          gutterBottom
                          variant="body1"
                          fontWeight="bold"
                          mr={1}
                        >
                          Course Application
                        </Typography>

                        {getStatusLabel(
                          singleTraineeApplication.training_course_application_status
                        )}
                      </Box>
                      <Box>
                        {singleTraineeApplication.training_course_application_assigned_batch_id ? (
                          <Link
                            to={`/training-batch/details/${singleTraineeApplication.training_course_application_assigned_batch_id}`}
                          >
                            -- {singleTraineeApplication.training_batch_name}
                          </Link>
                        ) : (
                          ""
                        )}
                      </Box>
                      <Typography gutterBottom variant="subtitle1">
                        {singleTraineeApplication.atab_training_course_name}
                      </Typography>
                    </Box>
                    <Box display={"flex"} alignItems={"center"}>
                      <Box>
                        {singleTraineeApplication.training_course_application_status ===
                        "pending" ? (
                          <Button
                            onClick={() => handleUpdate()}
                            variant="outlined"
                            color="error"
                            size="small"
                          >
                            Reject
                          </Button>
                        ) : (
                          ""
                        )}
                      </Box>
                      {singleTraineeApplication.training_course_application_status ===
                        "pending" && (
                        <Box sx={{ paddingLeft: "15px" }}>
                          <Tooltip title="Assign into batch ">
                            <IconButton
                              onClick={() => setOpen({ ...open, assign: true })}
                            >
                              <MdAssignmentAdd style={{ cursor: "pointer" }} />
                            </IconButton>
                          </Tooltip>
                        </Box>
                      )}
                      <Box sx={{ paddingLeft: "15px" }}>
                        <Tooltip title="Edit">
                          <IconButton>
                            <Link
                              to={`/training-trainee/application/details/${singleTraineeApplication.training_course_application_id}/edit`}
                            >
                              <EditIcon
                                sx={{
                                  cursor: "pointer",
                                }}
                              />
                            </Link>
                          </IconButton>
                        </Tooltip>
                      </Box>
                      <Box sx={{ paddingLeft: "15px" }}>
                        <Tooltip title="Print information">
                          <IconButton onClick={handlePrint}>
                            <PrintIcon
                              sx={{
                                cursor: "pointer",
                              }}
                            />
                          </IconButton>
                        </Tooltip>
                      </Box>
                    </Box>
                  </Box>
                  <CardContent>
                    <Grid
                      container
                      spacing={{ xs: 2, md: 3 }}
                      columns={{ xs: 12, sm: 12, md: 12, lg: 12 }}
                    >
                      <Grid item xs={12} md={2} textAlign="center">
                        <img
                          style={{ width: 150, height: 150 }}
                          src={
                            singleTraineeApplication.training_course_application_photo
                              ? `${imgUrl}/${singleTraineeApplication.training_course_application_photo}`
                              : "/static/images/avatars/avatar2.png"
                          }
                          alt="Trainee image"
                        />
                        <Link
                          to={`/training-trainee/details/${singleTraineeApplication.training_course_application_trainee_id}`}
                        >
                          <Button size="small">Trainee Account</Button>
                        </Link>
                      </Grid>
                      <Grid item xs={12} md={10}>
                        <Grid container>
                          <Grid item xs={12} sm={6}>
                            <Box>
                              <Typography
                                fontWeight="bold"
                                display="inline"
                                sx={{ pr: 1 }}
                              >
                                Applicant name:
                              </Typography>
                              <Typography display="inline" sx={{ pr: 1 }}>
                                {
                                  singleTraineeApplication?.training_course_application_name
                                }
                              </Typography>
                            </Box>
                            <Box>
                              <Typography
                                fontWeight="bold"
                                display="inline"
                                sx={{ pr: 1 }}
                              >
                                Gender:
                              </Typography>
                              <Typography display="inline" sx={{ pr: 1 }}>
                                {
                                  singleTraineeApplication?.training_course_application_gender
                                }
                              </Typography>
                            </Box>
                            <Box>
                              <Typography
                                fontWeight="bold"
                                display="inline"
                                sx={{ pr: 1 }}
                              >
                                Birthdate:
                              </Typography>
                              <Typography display="inline" sx={{ pr: 1 }}>
                                {moment(
                                  singleTraineeApplication.training_course_application_birthdate
                                ).format("DD-MM-YYYY")}{" "}
                                (
                                {moment(
                                  singleTraineeApplication.training_course_application_birthdate
                                ).fromNow(true)}
                                )
                              </Typography>
                            </Box>
                            <Box>
                              <Typography
                                fontWeight="bold"
                                display="inline"
                                sx={{ pr: 1 }}
                              >
                                Mobile:
                              </Typography>
                              <Typography display="inline" sx={{ pr: 1 }}>
                                {
                                  singleTraineeApplication?.training_course_application_mobile
                                }
                              </Typography>
                            </Box>
                            <Box>
                              <Typography
                                fontWeight="bold"
                                display="inline"
                                sx={{ pr: 1 }}
                              >
                                Whatsapp:
                              </Typography>
                              <Typography display="inline" sx={{ pr: 1 }}>
                                {
                                  singleTraineeApplication?.training_course_application_whatsapp_number
                                }
                              </Typography>
                            </Box>
                            <Box>
                              <Typography
                                fontWeight="bold"
                                display="inline"
                                sx={{ pr: 1 }}
                              >
                                Religion:
                              </Typography>
                              <Typography display="inline" sx={{ pr: 1 }}>
                                {
                                  singleTraineeApplication.training_course_application_religion
                                }
                              </Typography>
                            </Box>
                            <Box>
                              <Typography
                                fontWeight="bold"
                                display="inline"
                                sx={{ pr: 1 }}
                              >
                                Present Address:
                              </Typography>
                              <Typography display="inline" sx={{ pr: 1 }}>
                                {
                                  singleTraineeApplication?.training_course_application_present_address
                                }
                              </Typography>
                            </Box>
                            <Box>
                              <Typography
                                fontWeight="bold"
                                display="inline"
                                sx={{ pr: 1 }}
                              >
                                Birth place:
                              </Typography>
                              <Typography display="inline" sx={{ pr: 1 }}>
                                {
                                  singleTraineeApplication?.training_course_application_parmament_address
                                }
                              </Typography>
                            </Box>
                            <Box>
                              <Typography
                                fontWeight="bold"
                                display="inline"
                                sx={{ pr: 1 }}
                              >
                                Division:
                              </Typography>
                              <Typography display="inline" sx={{ pr: 1 }}>
                                {singleTraineeApplication?.division_name}
                              </Typography>
                            </Box>
                            <Box>
                              <Typography
                                fontWeight="bold"
                                display="inline"
                                sx={{ pr: 1 }}
                              >
                                Than:
                              </Typography>
                              <Typography display="inline" sx={{ pr: 1 }}>
                                {singleTraineeApplication?.thana_name}
                              </Typography>
                            </Box>
                            <Box>
                              <Typography
                                fontWeight="bold"
                                display="inline"
                                sx={{ pr: 1 }}
                              >
                                Post Code:
                              </Typography>
                              <Typography display="inline" sx={{ pr: 1 }}>
                                {
                                  singleTraineeApplication?.training_course_application_post_code
                                }
                              </Typography>
                            </Box>
                          </Grid>
                          <Grid item xs={12} sm={6}>
                            <Box>
                              <Typography
                                fontWeight="bold"
                                display="inline"
                                sx={{ pr: 1 }}
                              >
                                Blood Group:
                              </Typography>
                              <Typography display="inline" sx={{ pr: 1 }}>
                                {
                                  singleTraineeApplication.training_course_application_blood_group
                                }
                              </Typography>
                            </Box>
                            <Box>
                              <Typography
                                fontWeight="bold"
                                display="inline"
                                sx={{ pr: 1 }}
                              >
                                Maritial status:
                              </Typography>
                              <Typography display="inline" sx={{ pr: 1 }}>
                                {
                                  singleTraineeApplication.training_course_application_maritial_status
                                }
                              </Typography>
                            </Box>
                            <Box>
                              <CommonTypoGraphy
                                name="View signature"
                                onClick={() => {
                                  SetImageViewOpen(true);
                                  setImageFile(
                                    singleTraineeApplication.training_course_application_signature_file
                                  );
                                }}
                              />
                            </Box>
                            <Box>
                              <Typography
                                fontWeight="bold"
                                display="inline"
                                sx={{ pr: 1 }}
                              >
                                NID Number:
                              </Typography>
                              <Typography display="inline" sx={{ pr: 1 }}>
                                {
                                  singleTraineeApplication.training_course_application_nid_number
                                }
                              </Typography>
                            </Box>
                            <Box>
                              {" "}
                              <CommonTypoGraphy
                                name="View NID"
                                onClick={() => {
                                  SetImageViewOpen(true);
                                  setImageFile(
                                    singleTraineeApplication.training_course_application_nid_front_file
                                  );
                                }}
                              />
                            </Box>
                            <Box>
                              <Typography
                                fontWeight="bold"
                                display="inline"
                                sx={{ pr: 1 }}
                              >
                                Last education Qualification:
                              </Typography>
                              <Typography display="inline" sx={{ pr: 1 }}>
                                {
                                  singleTraineeApplication?.training_course_application_last_academic_qualification
                                }
                              </Typography>
                            </Box>
                            <Box>
                              <CommonTypoGraphy
                                name="View Certificate"
                                onClick={() => {
                                  SetImageViewOpen(true);
                                  setImageFile(
                                    singleTraineeApplication.training_course_application_last_academic_certificate_file
                                  );
                                }}
                              />
                            </Box>
                            <Box>
                              <CommonTypoGraphy
                                name="View CV"
                                onClick={() => {
                                  SetImageViewOpen(true);
                                  setImageFile(
                                    singleTraineeApplication.training_course_application_cv_file
                                  );
                                }}
                              />
                            </Box>
                            <Box>
                              <Typography
                                fontWeight="bold"
                                display="inline"
                                sx={{ pr: 1 }}
                              >
                                District:
                              </Typography>
                              <Typography display="inline" sx={{ pr: 1 }}>
                                {singleTraineeApplication?.district_name}
                              </Typography>
                            </Box>
                            <Box>
                              <Typography
                                fontWeight="bold"
                                display="inline"
                                sx={{ pr: 1 }}
                              >
                                Area:
                              </Typography>
                              <Typography display="inline" sx={{ pr: 1 }}>
                                {singleTraineeApplication?.area_name}
                              </Typography>
                            </Box>
                          </Grid>
                        </Grid>
                      </Grid>
                    </Grid>
                  </CardContent>
                </Card>
                <Card sx={{ mb: 2 }}>
                  <CardHeader title="Guardian information" />
                  <CardContent>
                    <Grid container rowGap={1}>
                      <Grid md={6} lg={4} xs={12} item>
                        <Box>
                          <Typography
                            variant="body1"
                            fontWeight="bold"
                            color="text.primary"
                            display="inline"
                            sx={{ pr: 1 }}
                            gutterBottom
                          >
                            Fathers name:
                          </Typography>
                          <Typography
                            variant="body1"
                            color="text.primary"
                            display="inline"
                            sx={{ pr: 1 }}
                            gutterBottom
                          >
                            {
                              singleTraineeApplication?.training_course_application_fathers_name
                            }
                          </Typography>
                        </Box>
                        <Box>
                          <Typography
                            variant="body1"
                            fontWeight="bold"
                            color="text.primary"
                            display="inline"
                            sx={{ pr: 1 }}
                            gutterBottom
                          >
                            Fathers occupation:
                          </Typography>
                          <Typography
                            variant="body1"
                            color="text.primary"
                            display="inline"
                            sx={{ pr: 1 }}
                            gutterBottom
                          >
                            {
                              singleTraineeApplication?.training_course_application_father_occupation
                            }
                          </Typography>
                        </Box>
                        <Box>
                          <Typography
                            variant="body1"
                            fontWeight="bold"
                            color="text.primary"
                            display="inline"
                            sx={{ pr: 1 }}
                            gutterBottom
                          >
                            Family income:
                          </Typography>
                          <Typography
                            variant="body1"
                            color="text.primary"
                            display="inline"
                            sx={{ pr: 1 }}
                            gutterBottom
                          >
                            {
                              singleTraineeApplication.training_course_application_parents_income
                            }
                          </Typography>
                        </Box>
                      </Grid>
                      <Grid md={6} lg={4} xs={12} item>
                        <Box>
                          <Typography
                            variant="body1"
                            fontWeight="bold"
                            color="text.primary"
                            display="inline"
                            sx={{ pr: 1 }}
                            gutterBottom
                          >
                            Mothers name:
                          </Typography>
                          <Typography
                            variant="body1"
                            color="text.primary"
                            display="inline"
                            sx={{ pr: 1 }}
                            gutterBottom
                          >
                            {
                              singleTraineeApplication?.training_course_application_mothers_name
                            }
                          </Typography>
                        </Box>
                        <Box>
                          <Typography
                            variant="body1"
                            fontWeight="bold"
                            color="text.primary"
                            display="inline"
                            sx={{ pr: 1 }}
                            gutterBottom
                          >
                            Mothers occupation:
                          </Typography>
                          <Typography
                            variant="body1"
                            color="text.primary"
                            display="inline"
                            sx={{ pr: 1 }}
                            gutterBottom
                          >
                            {
                              singleTraineeApplication?.training_course_application_mother_occupation
                            }
                          </Typography>
                        </Box>
                      </Grid>
                      <Grid md={6} lg={4} xs={12} item>
                        <Box>
                          <Typography
                            variant="body1"
                            fontWeight="bold"
                            color="text.primary"
                            display="inline"
                            sx={{ pr: 1 }}
                            gutterBottom
                          >
                            Local guardian:
                          </Typography>
                          <Typography
                            variant="body1"
                            color="text.primary"
                            display="inline"
                            sx={{ pr: 1 }}
                            gutterBottom
                          >
                            {
                              singleTraineeApplication?.training_course_application_guardian_name
                            }
                          </Typography>
                        </Box>
                        <Box>
                          <Typography
                            variant="body1"
                            fontWeight="bold"
                            color="text.primary"
                            display="inline"
                            sx={{ pr: 1 }}
                            gutterBottom
                          >
                            Relation:
                          </Typography>
                          <Typography
                            variant="body1"
                            color="text.primary"
                            display="inline"
                            sx={{ pr: 1 }}
                            gutterBottom
                          >
                            {
                              singleTraineeApplication?.training_course_application_guardian_relation
                            }
                          </Typography>
                        </Box>
                        <Box>
                          <Typography
                            variant="body1"
                            fontWeight="bold"
                            color="text.primary"
                            display="inline"
                            sx={{ pr: 1 }}
                            gutterBottom
                          >
                            Address:
                          </Typography>
                          <Typography
                            variant="body1"
                            color="text.primary"
                            display="inline"
                            sx={{ pr: 1 }}
                            gutterBottom
                          >
                            {
                              singleTraineeApplication?.training_course_application_guardian_address
                            }
                          </Typography>
                        </Box>
                      </Grid>
                    </Grid>
                  </CardContent>
                </Card>
                <Card>
                  <CardContent>
                    <Grid container>
                      <Grid item xs={12} md={6}>
                        <Typography
                          variant="h4"
                          color="text.primary"
                          display="inline"
                          fontWeight="bold"
                          sx={{ mb: 2 }}
                          gutterBottom
                        >
                          Known by
                        </Typography>
                        <Box>
                          <Box>
                            <Typography
                              variant="body1"
                              color="text.primary"
                              display="inline"
                              gutterBottom
                            >
                              Know about our training from{" "}
                              {
                                singleTraineeApplication.training_course_application_info_source
                              }
                            </Typography>
                          </Box>
                          {singleTraineeApplication.training_course_application_member_id ? (
                            <Box>
                              <Typography
                                variant="body1"
                                color="text.primary"
                                gutterBottom
                              >
                                Reference ATAB Member:{" "}
                                <Link
                                  to={`/member-list/${singleTraineeApplication.training_course_application_member_id}`}
                                >
                                  {
                                    singleTraineeApplication.user_member_company_name
                                  }
                                </Link>
                              </Typography>
                              <Typography
                                variant="body1"
                                color="text.primary"
                                gutterBottom
                              >
                                Member document:{" "}
                                <CommonTypoGraphy
                                  name="View"
                                  onClick={() => {
                                    SetImageViewOpen(true);
                                    setImageFile(
                                      singleTraineeApplication.training_course_application_member_document
                                    );
                                  }}
                                />
                              </Typography>
                            </Box>
                          ) : (
                            ""
                          )}
                        </Box>
                      </Grid>
                      {singleTraineeApplication.training_course_application_cgpa ? (
                        <Grid item xs={12} md={6}>
                          <Typography
                            variant="h4"
                            color="text.primary"
                            display="inline"
                            fontWeight="bold"
                            sx={{ mb: 2 }}
                            gutterBottom
                          >
                            Education information
                          </Typography>
                          <Box>
                            <Box>
                              <Typography
                                variant="body1"
                                fontWeight="bold"
                                color="text.primary"
                                display="inline"
                                sx={{ pr: 1 }}
                                gutterBottom
                              >
                                Degree:
                              </Typography>
                              <Typography
                                variant="body1"
                                color="text.primary"
                                display="inline"
                                sx={{ pr: 1 }}
                                gutterBottom
                              >
                                {
                                  singleTraineeApplication.training_course_application_degree
                                }
                              </Typography>
                            </Box>
                            <Box>
                              <Typography
                                variant="body1"
                                fontWeight="bold"
                                color="text.primary"
                                display="inline"
                                sx={{ pr: 1 }}
                                gutterBottom
                              >
                                Institute:
                              </Typography>
                              <Typography
                                variant="body1"
                                color="text.primary"
                                display="inline"
                                sx={{ pr: 1 }}
                                gutterBottom
                              >
                                {
                                  singleTraineeApplication.training_course_application_institute
                                }
                              </Typography>
                            </Box>
                            <Box>
                              <Typography
                                variant="body1"
                                fontWeight="bold"
                                color="text.primary"
                                display="inline"
                                sx={{ pr: 1 }}
                                gutterBottom
                              >
                                Board:
                              </Typography>
                              <Typography
                                variant="body1"
                                color="text.primary"
                                display="inline"
                                sx={{ pr: 1 }}
                                gutterBottom
                              >
                                {
                                  singleTraineeApplication?.training_course_application_board
                                }
                              </Typography>
                            </Box>
                            <Box>
                              <Typography
                                variant="body1"
                                fontWeight="bold"
                                color="text.primary"
                                display="inline"
                                sx={{ pr: 1 }}
                                gutterBottom
                              >
                                CGPA:
                              </Typography>
                              <Typography
                                variant="body1"
                                color="text.primary"
                                display="inline"
                                sx={{ pr: 1 }}
                                gutterBottom
                              >
                                {
                                  singleTraineeApplication.training_course_application_cgpa
                                }
                              </Typography>
                            </Box>
                          </Box>
                        </Grid>
                      ) : (
                        ""
                      )}
                    </Grid>
                  </CardContent>
                </Card>
              </Box>
            ) : (
              <Typography textAlign="center">Not Found</Typography>
            )}
          </Container>
          <ModalsWrapper
            modalData={{ title: "Update trainee application " }}
            setShowModal={setOpen}
            showModal={open.update}
          >
            <UpdateSingleTraineeApplication />
          </ModalsWrapper>
        </>
      ) : (
        <GlobalLoader />
      )}{" "}
      <Box display={"none"}>
        <ShortCourseForm
          componentRef={componentRef}
          singleTraineeApplication={singleTraineeApplication}
        />
      </Box>{" "}
      <PdfAndImageView
        setOpen={SetImageViewOpen}
        open={imageViewOpen}
        file={imageFile}
      />{" "}
      <ModalsWrapper
        modalData={{ title: "Assign into batch" }}
        setShowModal={setOpen}
        showModal={open.assign}
      >
        <AssignBatch
          details={singleTraineeApplication}
          handelClose={() => setOpen({ ...open, assign: false })}
          setReload={() => setReload(!reload)}
        />
      </ModalsWrapper>
    </>
  );
};

export default SingleTraineeApplication;
