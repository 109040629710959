import { Button } from "@mui/material";
import AddIcon from "@mui/icons-material/Add";
type IProps = {
  onClick: any;
  title: string;
};

const CreateButton = ({ onClick, title }: IProps) => {
  return (
    <>
      <Button
        onClick={onClick}
        variant="contained"
        startIcon={<AddIcon />}
        size="small"
      >
        {title}
      </Button>
    </>
  );
};

export default CreateButton;
