import React from "react";
import { IMoneyReceipt } from "src/Types/module/common/commonTypes";
import {
  IconButton,
  TableCell,
  TableRow,
  Tooltip,
  useTheme,
} from "@mui/material";
import { Link } from "react-router-dom";
import moment from "moment";
import { Visibility } from "@mui/icons-material";

type IProps = {
  sInvoice: IMoneyReceipt;
};
const MoneyReceiptTableRow = ({ sInvoice: invoiceData }: IProps) => {
  const theme = useTheme();
  return (
    <TableRow hover>
      <TableCell>
        <Link to={`/member-list/${invoiceData.user_member_id}`}>
          {invoiceData.user_member_company_name
            .toLowerCase()
            .split(" ")
            .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
            .join(" ")}
        </Link>
      </TableCell>
      <TableCell>{invoiceData.money_receipt_no}</TableCell>
      <TableCell>{invoiceData.total_collected_amount}</TableCell>
      <TableCell>
        {moment(invoiceData.created_at).format("DD/MM/YYYY")}
      </TableCell>

      {/* <TableCell>
            {getStatusLabel(invoiceData.atab_payment_invoice_status)}
          </TableCell> */}
      <TableCell>
        <Tooltip title="View" arrow>
          <Link to={`${invoiceData.id}`}>
            <IconButton
              sx={{
                "&:hover": {
                  background: theme.colors.primary.lighter,
                },
                color: theme.palette.primary.main,
              }}
              color="inherit"
              size="small"
            >
              <Visibility fontSize="small" />
            </IconButton>
          </Link>
        </Tooltip>
      </TableCell>
    </TableRow>
  );
};

export default MoneyReceiptTableRow;
