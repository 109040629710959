import React, { useState } from "react";
import {
  Divider,
  Box,
  Card,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TablePagination,
  TableRow,
  TableContainer,
  CardHeader,
  CardContent,
  Container,
  Grid,
  Button,
} from "@mui/material";
import { TableLoader, TableNotFound } from "src/Utils/TableLoader";
import ModalsWrapper from "src/components/Modals/ModalsWrapper";
import CreateVoucherEntry from "../components/CreateVoucherEntry";
import CreateButton from "src/common/CreateButton";

const VoucherEntry = () => {
  const [open, setOpen] = useState(false);
  return (
    <Container sx={{ mt: 4 }} maxWidth="xl">
      <Grid
        container
        direction="row"
        justifyContent="center"
        alignItems="stretch"
        spacing={3}
      >
        <Grid item xs={12}>
          <Card>
            <CardHeader
              action={
                <Box
                  sx={{
                    display: "flex",
                    justifyContent: "space-between",
                    gap: 2,
                  }}
                >
                  {/* <TextField
                    label="Search invoice"
                    id="standard-search"
                    type="search"
                    variant="outlined"
                    onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                      setSearchInvoiceVal(e.target.value)
                    }
                  /> */}
                  {/* <FormControl variant="outlined" sx={{ width: "150px" }}>
                    <InputLabel>Status</InputLabel>
                    <Select
                      value={status}
                      onChange={(e) => handleStatus(e)}
                      label="Status"
                    >
                      {statusOptions.map((statusOption) => (
                        <MenuItem key={statusOption.id} value={statusOption.id}>
                          {statusOption.name}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl> */}
                  {/* <Button onClick={() => setOpen(true)} variant="contained">
                    Create an invoice
                  </Button> */}
                  <CreateButton
                    onClick={() => setOpen(true)}
                    title={"Voucher entry"}
                  />
                </Box>
              }
              title="Voucher Entry List"
            />
            <Divider />

            <CardContent>
              <TableContainer>
                <Table>
                  <TableHead>
                    <TableRow>
                      <TableCell>SL No</TableCell>
                      <TableCell>Account Head</TableCell>
                      <TableCell>Description</TableCell>
                      <TableCell>Dr. Amount</TableCell>
                      <TableCell>Cr. Amount</TableCell>
                      <TableCell>Actions</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {/* {loading ? (
                      <TableLoader />
                    ) : allInvoices.length ? (
                      <>
                        {allInvoices.map((sInvoice) => (
                          <InvoiceTableRow
                            key={sInvoice.id}
                            sInvoice={sInvoice}
                          />
                        ))}
                      </>
                    ) : (
                      <TableNotFound />
                    )} */}
                    <TableNotFound />
                  </TableBody>
                </Table>
              </TableContainer>

              {/* <Box p={2}>
                <TablePagination
                  component="div"
                  count={total}
                  onPageChange={handlePageChange}
                  onRowsPerPageChange={handleLimitChange}
                  page={page && page}
                  rowsPerPage={limit}
                  rowsPerPageOptions={[25, 50, 75, 100]}
                />
              </Box> */}
            </CardContent>

            {/* Modal */}
            <ModalsWrapper
              modalData={{ title: "Add voucher entry" }}
              setShowModal={setOpen}
              showModal={open}
            >
              <CreateVoucherEntry />
            </ModalsWrapper>
          </Card>
        </Grid>
      </Grid>
    </Container>
  );
};

export default VoucherEntry;
