import {
  Box,
  IconButton,
  TableCell,
  TableRow,
  Tooltip,
  Typography,
  useTheme,
} from "@mui/material";
import VisibilityIcon from "@mui/icons-material/Visibility";
import { Link } from "react-router-dom";
import { getStatusLabel } from "src/Utils/Combined";
import { ITrainingInvoiceList } from "src/Utils/type";
import moment from "moment";
import { imgUrl } from "src/Helpers/utils/Constant";

type IProps = {
  trainingInvoiceList: ITrainingInvoiceList;
};

function TrainingInvoiceTableRow({ trainingInvoiceList }: IProps) {
  const theme = useTheme();

  return (
    <TableRow hover>
      <TableCell>
        <Typography variant="body1" color="text.primary" gutterBottom noWrap>
          {trainingInvoiceList.training_payment_invoice_id}
        </Typography>
      </TableCell>
      <TableCell>
        <Box sx={{ display: "flex", alignItems: "center" }}>
          <img
            width={30}
            height={30}
            style={{
              borderRadius: "50%",
              marginRight: "10px",
            }}
            src={
              trainingInvoiceList.user_training_trainee_photo
                ? `${imgUrl}/${trainingInvoiceList.user_training_trainee_photo}`
                : "/static/images/avatars/avatar2.png"
            }
            alt=""
          />
          <Typography variant="body1" color="text.primary" gutterBottom noWrap>
            {trainingInvoiceList.user_training_trainee_name
              .toLowerCase()
              .split(" ")
              .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
              .join(" ")}
          </Typography>
        </Box>
      </TableCell>
      <TableCell>
        <Typography variant="body1" color="text.primary" gutterBottom noWrap>
          {trainingInvoiceList.training_payment_invoice_total_amount}
        </Typography>
      </TableCell>
      <TableCell>
        <Typography variant="body1" color="text.primary" gutterBottom noWrap>
          {trainingInvoiceList.training_payment_invoice_grand_total}
        </Typography>
      </TableCell>
      <TableCell>
        <Typography variant="body1" color="text.primary" gutterBottom noWrap>
          {trainingInvoiceList.training_payment_invoice_discount_amount}
        </Typography>
      </TableCell>
      <TableCell>
        <Typography variant="body1" color="text.primary" gutterBottom noWrap>
          {trainingInvoiceList.training_payment_invoice_payment_method
            ? trainingInvoiceList.training_payment_invoice_payment_method
            : "Yet not payment"}
        </Typography>
      </TableCell>
      <TableCell>
        <Typography variant="body1" color="text.primary" gutterBottom noWrap>
          {getStatusLabel(trainingInvoiceList.training_payment_invoice_status)}
        </Typography>
      </TableCell>
      <TableCell>
        <Typography variant="body1" color="text.primary" gutterBottom noWrap>
          {moment(
            trainingInvoiceList.training_payment_invoice_issue_date
          ).format("LL")}
        </Typography>
      </TableCell>
      <TableCell>
        <Tooltip title="view" arrow>
          <Link
            to={`/training-invoice/details/${trainingInvoiceList.training_payment_invoice_id.toString()}`}
          >
            <IconButton
              sx={{
                "&:hover": {
                  background: theme.colors.primary.lighter,
                },
                color: theme.palette.primary.main,
              }}
              color="inherit"
              size="small"
            >
              <VisibilityIcon fontSize="small" />
            </IconButton>
          </Link>
        </Tooltip>
      </TableCell>
    </TableRow>
  );
}

export default TrainingInvoiceTableRow;
