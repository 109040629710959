import {
  Divider,
  Card,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TablePagination,
  TableRow,
  TableContainer,
  CardHeader,
  CardContent,
  Container,
  Grid,
} from "@mui/material";
import { useState } from "react";
import CreateButton from "src/common/CreateButton";
import ModalsWrapper from "src/components/Modals/ModalsWrapper";
import { CreateBank } from "../components/CreateBank";

const Bank = () => {
  const [open, setOpen] = useState(false);
  return (
    <Container sx={{ mt: 4 }} maxWidth="xl">
      <Grid
        container
        direction="row"
        justifyContent="center"
        alignItems="stretch"
        spacing={3}
      >
        <Grid item xs={12}>
          <Card>
            <CardHeader
              title="Bank"
              action={
                <CreateButton
                  onClick={() => setOpen(true)}
                  title={"Create Bank"}
                />
              }
            />
            <Divider />

            <CardContent>
              <TableContainer>
                <Table>
                  <TableHead>
                    <TableRow>
                      <TableCell>Name</TableCell>
                      <TableCell>BranchName</TableCell>
                      <TableCell>Email</TableCell>
                      <TableCell>Phone</TableCell>
                      <TableCell>Fax</TableCell>
                      <TableCell>Action</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {/* {loading ? (
                      <TableLoader />
                    ) : allInvoices.length ? (
                      <>
                        {allInvoices.map((sInvoice) => (
                          <InvoiceTableRow
                            key={sInvoice.id}
                            sInvoice={sInvoice}
                          />
                        ))}
                      </>
                    ) : (
                      <TableNotFound />
                    )} */}
                  </TableBody>
                </Table>
              </TableContainer>

              {/* <Box p={2}>
                <TablePagination
                  component="div"
                  count={total}
                  onPageChange={handlePageChange}
                  onRowsPerPageChange={handleLimitChange}
                  page={page && page}
                  rowsPerPage={limit}
                  rowsPerPageOptions={[25, 50, 75, 100]}
                />
              </Box> */}
            </CardContent>

            {/* Modal */}
            <ModalsWrapper
              modalData={{ title: "Create Bank" }}
              setShowModal={setOpen}
              showModal={open}
            >
              <CreateBank />
            </ModalsWrapper>
          </Card>
        </Grid>
      </Grid>
    </Container>
  );
};

export default Bank;
