import { TextField } from "@mui/material";
import { DatePicker } from "@mui/x-date-pickers";

type IProps = {
  label: string;
  onChange?: any;
  value?: any;
};
export const CommonDatePicker = ({ label, onChange, value }: IProps) => {
  return (
    <DatePicker
      label={label}
      // value={value}
      format="YYYY-MM-DD"
      onChange={onChange}
      // renderInput={(params) => (
      //   <TextField {...params} sx={{ width: "100%" }} size="small" />
      // )}
    />
  );
};
