import {
  Box,
  IconButton,
  TableCell,
  TableRow,
  Tooltip,
  Button,
} from "@mui/material";
import moment from "moment";
import { Link, useParams } from "react-router-dom";
import { IBatchStudent } from "src/Types/module/courses/trainee";
import VisibilityIcon from "@mui/icons-material/Visibility";
import DeleteIcon from "@mui/icons-material/Delete";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import { useState } from "react";
import { url } from "src/Helpers/utils/Constant";
import Toaster from "src/Toaster/Toaster";
import { HttpClient } from "src/Helpers/http/http";

interface IProps {
  student: IBatchStudent;
  setReload: any;
  courseId: number;
}

const SingleBatchStudentRow = ({ student, courseId, setReload }: IProps) => {
  const { id: batchID } = useParams();
  const [open, setOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  const handelRemoveStudent = (id: number) => {
    (async () => {
      try {
        setLoading(true);
        const data: any = await HttpClient.delete(
          `${url}/api/training/batch/delete/student/from/batch/${id}?batch_id=${batchID}&course_id=${courseId}`
        );

        if (data.success) {
          // setBatchDetails(data.data);
          Toaster().fire({ icon: "success", title: data.message });
          setReload((prv) => !prv);
          setLoading(false);
        } else {
          setLoading(false);
        }
      } catch (err) {
        Toaster().fire({ icon: "info", title: "No batch found" });
        setLoading(false);
      }
    })();
  };
  return (
    <>
      <TableRow>
        <TableCell component="th" scope="row">
          {student.user_training_trainee_id}
        </TableCell>
        <TableCell component="th" scope="row">
          {student.user_training_trainee_name
            .toLowerCase()
            .split(" ")
            .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
            .join(" ")}
        </TableCell>
        <TableCell align="right">
          {moment(student.training_student_batch_join_date).format("MMM Do YY")}
        </TableCell>
        <TableCell align="right">
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
            }}
          >
            <Tooltip title="view" arrow>
              <IconButton
                color="inherit"
                size="small"
                style={{ paddingTop: "10px" }}
              >
                <Link
                  to={`/training-trainee/application/details/${student.training_course_application_id}`}
                >
                  <VisibilityIcon fontSize="small" />
                </Link>
              </IconButton>
            </Tooltip>
            <Tooltip title="Remove" arrow>
              <IconButton
                color="inherit"
                size="small"
                onClick={() => setOpen(true)}
              >
                <DeleteIcon fontSize="small" style={{ color: "red" }} />
              </IconButton>
            </Tooltip>
          </Box>
        </TableCell>
      </TableRow>
      <Dialog
        open={open}
        onClose={() => setOpen(false)}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title" sx={{ fontWeight: "bold" }}>
          {"Remove student form this batch"}
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            Are you sure to remove this student?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button
            size="small"
            variant="outlined"
            disabled={loading}
            onClick={() => {
              setOpen(false);
              handelRemoveStudent(student.user_training_trainee_id);
            }}
            autoFocus
          >
            Yes
          </Button>
          <Button
            size="small"
            variant="outlined"
            onClick={() => setOpen(false)}
            color="error"
          >
            No
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default SingleBatchStudentRow;
