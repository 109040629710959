import {
  Divider,
  Box,
  Card,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TablePagination,
  TableRow,
  TableContainer,
  CardHeader,
  CardContent,
  Container,
  Grid,
  Button,
  TextField,
  Typography,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
} from "@mui/material";
import { useState } from "react";
import { IDate } from "src/Types/module/serviceRequest/serviceRequestTypes";
import { TableLoader, TableNotFound } from "src/Utils/TableLoader";
import { CommonDatePicker } from "src/common/CommonDatePicker";

const Ledger = () => {
  const [date, setDate] = useState<IDate>({
    startDate: null,
    endDate: null,
  });
  const [selectFilter, setSelectFilter] = useState({
    zone: "Dhaka",
    subscription: "",
  });
  const [filterItem, setFilterItem] = useState({
    zone: [],
    subscription: "",
  });
  return (
    <Container sx={{ mt: 4 }} maxWidth="xl">
      <Grid item xs={12} mb={2}>
        <Card sx={{ p: 2 }}>
          <Grid
            sx={{
              display: "flex",
              justifyContent: "end",
              alignItems: "center",
              gap: 1,
            }}
          >
            <Grid
              item
              sx={{
                display: "flex",
                alignItems: "center",
                gap: 1,
              }}
            >
              <FormControl sx={{ width: "150px" }}>
                <InputLabel id="demo-simple-select-label">
                  Account Head
                </InputLabel>
                <Select
                  size="small"
                  labelId="demo-simple-select-label"
                  id="demo-simple-select"
                  value={selectFilter.zone}
                  label="Account Head"
                  onChange={(e: any) =>
                    setSelectFilter({
                      ...selectFilter,
                      zone: e.target.value,
                    })
                  }
                >
                  {filterItem.zone.map((sZone) => {
                    return (
                      <MenuItem value={sZone} key={sZone.zone_id}>
                        {sZone.zone_name}
                      </MenuItem>
                    );
                  })}
                </Select>
              </FormControl>
            </Grid>
            <Grid
              sx={{
                display: "flex",
                justifyContent: "end",
                alignItems: "center",
                gap: 1,
              }}
            >
              <Grid
                item
                sx={{
                  display: "flex",
                  alignItems: "center",

                  gap: 1,
                }}
              >
                <Typography
                  fontWeight={"bold"}
                  variant="h6"
                  component="h6"
                  gutterBottom
                >
                  From
                </Typography>

                <CommonDatePicker
                  onChange={(e: any) => setDate({ ...date, startDate: e })}
                  label={"Start Date"}
                  value={date.startDate}
                />
              </Grid>
              <Grid
                item
                sx={{
                  display: "flex",
                  alignItems: "center",
                  gap: 1,
                }}
              >
                <Typography
                  fontWeight={"bold"}
                  variant="h6"
                  component="h6"
                  gutterBottom
                >
                  To
                </Typography>
                <CommonDatePicker
                  onChange={(e: any) => setDate({ ...date, endDate: e })}
                  label={"End Date"}
                  value={date.endDate}
                />
              </Grid>
            </Grid>
          </Grid>
        </Card>
      </Grid>
      <Grid
        container
        direction="row"
        justifyContent="center"
        alignItems="stretch"
        spacing={3}
      >
        <Grid item xs={12}>
          <Card>
            <CardHeader
              action={
                <Box
                  sx={{
                    display: "flex",
                    justifyContent: "space-between",
                    gap: 2,
                  }}
                >
                  {/* <TextField
                    label="Search invoice"
                    id="standard-search"
                    type="search"
                    variant="outlined"
                    onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                      setSearchInvoiceVal(e.target.value)
                    }
                  /> */}
                  {/* <FormControl variant="outlined" sx={{ width: "150px" }}>
                    <InputLabel>Status</InputLabel>
                    <Select
                      value={status}
                      onChange={(e) => handleStatus(e)}
                      label="Status"
                    >
                      {statusOptions.map((statusOption) => (
                        <MenuItem key={statusOption.id} value={statusOption.id}>
                          {statusOption.name}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl> */}
                  {/* <Button onClick={() => setOpen(true)} variant="contained">
                    Create an invoice
                  </Button> */}
                </Box>
              }
              title="Ledger"
            />
            <Divider />

            <CardContent>
              <TableContainer>
                <Table>
                  <TableHead>
                    <TableRow>
                      <TableCell>Sl. No.</TableCell>
                      <TableCell>Voucher No.</TableCell>
                      <TableCell>Cheque No.</TableCell>
                      <TableCell>Date</TableCell>
                      <TableCell>A/C Group</TableCell>
                      <TableCell>A/C Code</TableCell>
                      <TableCell>Particulars</TableCell>
                      <TableCell>Description</TableCell>
                      <TableCell>Debit (BDT)</TableCell>
                      <TableCell>Credit (BDT)</TableCell>
                      <TableCell>Balance</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {/* {loading ? (
                      <TableLoader />
                    ) : allInvoices.length ? (
                      <>
                        {allInvoices.map((sInvoice) => (
                          <InvoiceTableRow
                            key={sInvoice.id}
                            sInvoice={sInvoice}
                          />
                        ))}
                      </>
                    ) : (
                      <TableNotFound />
                    )} */}
                  </TableBody>
                </Table>
              </TableContainer>

              {/* <Box p={2}>
                <TablePagination
                  component="div"
                  count={total}
                  onPageChange={handlePageChange}
                  onRowsPerPageChange={handleLimitChange}
                  page={page && page}
                  rowsPerPage={limit}
                  rowsPerPageOptions={[25, 50, 75, 100]}
                />
              </Box> */}
            </CardContent>

            {/* Modal */}

            {/* manual invoice  */}
            {/* <ModalsWrapper
              modalData={{ title: "Create manual invoice" }}
              setShowModal={setOpenManual}
              showModal={openManual}
            >
              <CreateManualInvoice handleClose={() => setOpenManual(false)} />
            </ModalsWrapper> */}
          </Card>
        </Grid>
      </Grid>
    </Container>
  );
};

export default Ledger;
