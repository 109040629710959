import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from "@mui/material";
import useTheme from "@mui/material/styles/useTheme";
import moment from "moment";
import { TableNotFound } from "src/Utils/TableLoader";
import { singleMoneyReceipt } from "src/Types/module/common/commonTypes";

const MoneyReceiptItems = ({
  singleInvoiceDetails,
}: {
  singleInvoiceDetails: singleMoneyReceipt;
}) => {
  const theme = useTheme();
  return (
    <>
      <Typography
        sx={{ textDecoration: "underline" }}
        mt={8}
        mb={3}
        color="text."
        align="center"
        variant="h4"
      >
        Money Receive Items
      </Typography>

      <TableContainer>
        <Table>
          <TableHead sx={{ backgroundColor: theme.colors.primary.main }}>
            <TableRow>
              <TableCell sx={{ color: theme.colors.alpha.white[100] }}>
                ID
              </TableCell>
              <TableCell
                sx={{ color: theme.colors.alpha.white[100] }}
                align="center"
              >
                Check NO
              </TableCell>
              <TableCell
                sx={{ color: theme.colors.alpha.white[100] }}
                align="center"
              >
                Year
              </TableCell>
              <TableCell
                sx={{ color: theme.colors.alpha.white[100] }}
                align="center"
              >
                Amount
              </TableCell>
              <TableCell
                sx={{ color: theme.colors.alpha.white[100] }}
                align="center"
              >
                Date
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {singleInvoiceDetails?.money_receipt_details?.length ? (
              <>
                {singleInvoiceDetails?.money_receipt_details?.map(
                  (details, index) => (
                    <TableRow key={index}>
                      <TableCell>{details?.detail_id}</TableCell>
                      <TableCell align="center">{details?.cheque_no}</TableCell>
                      <TableCell align="center">{details?.year}</TableCell>
                      <TableCell align="center">
                        {details?.collected_amount}
                      </TableCell>
                      <TableCell align="center">
                        {moment(details?.subscription_date).format(
                          "DD/MM/YYYY"
                        )}
                      </TableCell>
                    </TableRow>
                  )
                )}
              </>
            ) : (
              <TableNotFound />
            )}
          </TableBody>
        </Table>
      </TableContainer>
    </>
  );
};

export default MoneyReceiptItems;
