import {
  Divider,
  Card,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TablePagination,
  TableRow,
  TableContainer,
  CardHeader,
  CardContent,
  Container,
  Grid,
} from "@mui/material";
import { useState } from "react";
import CreateButton from "src/common/CreateButton";
import ModalsWrapper from "src/components/Modals/ModalsWrapper";
import CreateChequeRegisterEntry from "../components/CreateChequeRegisterEntry";

const ChequeRegisterEntry = () => {
  const [open, setOpen] = useState(false);
  return (
    <div>
      {" "}
      <Container sx={{ mt: 4 }} maxWidth="xl">
        <Grid
          container
          direction="row"
          justifyContent="center"
          alignItems="stretch"
          spacing={3}
        >
          <Grid item xs={12}>
            <Card>
              <CardHeader
                title="Cheque Register Summary"
                action={
                  <CreateButton
                    onClick={() => setOpen(true)}
                    title={"Cheque Register Entry"}
                  />
                }
              />
              <Divider />

              <CardContent>
                <TableContainer>
                  <Table>
                    <TableHead>
                      <TableRow>
                        <TableCell>Company</TableCell>
                        <TableCell>Bank</TableCell>
                        <TableCell>BranchName</TableCell>
                        <TableCell>Leafs</TableCell>
                        <TableCell>Starting Number</TableCell>
                        <TableCell>unused</TableCell>
                        <TableCell>Issued</TableCell>
                        <TableCell>Bounced</TableCell>
                        <TableCell>Ruined</TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {/* {loading ? (
                      <TableLoader />
                    ) : allInvoices.length ? (
                      <>
                        {allInvoices.map((sInvoice) => (
                          <InvoiceTableRow
                            key={sInvoice.id}
                            sInvoice={sInvoice}
                          />
                        ))}
                      </>
                    ) : (
                      <TableNotFound />
                    )} */}
                    </TableBody>
                  </Table>
                </TableContainer>

                {/* <Box p={2}>
                <TablePagination
                  component="div"
                  count={total}
                  onPageChange={handlePageChange}
                  onRowsPerPageChange={handleLimitChange}
                  page={page && page}
                  rowsPerPage={limit}
                  rowsPerPageOptions={[25, 50, 75, 100]}
                />
              </Box> */}
              </CardContent>

              {/* Modal */}
              <ModalsWrapper
                modalData={{ title: "Cheque Register Entry " }}
                setShowModal={setOpen}
                showModal={open}
              >
                <CreateChequeRegisterEntry />
              </ModalsWrapper>
            </Card>
          </Grid>
        </Grid>
      </Container>
    </div>
  );
};

export default ChequeRegisterEntry;
