import {
  ListSubheader,
  List,
  Button,
  ListItem,
  Typography,
  Accordion,
  AccordionSummary,
  AccordionDetails,
} from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import TableChartTwoToneIcon from "@mui/icons-material/TableChartTwoTone";
import { submenuWrapper } from "src/Utils/DashboardUtils";
import { useAuthContext } from "src/components/Context/AuthContext/AuthContext";
const SubMenuWrapper = submenuWrapper();

const AccountsSideBar = ({
  handleChange,
  expanded,
  closeSidebar,
  RouterLink,
}) => {
  const { user } = useAuthContext();
  return (
    <>
      <List
        component="div"
        subheader={
          <ListSubheader component="div" disableSticky>
            Accounts
          </ListSubheader>
        }
      >
        <SubMenuWrapper>
          {user.permissions.subModules.includes("pure-accounting") && (
            <List className="accordion-submodule" component="div">
              {/* vouchers  */}
              <Accordion
                className="accordion"
                expanded={expanded === "panel36"}
                onChange={handleChange("panel36")}
              >
                <AccordionSummary
                  expandIcon={<ExpandMoreIcon className="expand-icon" />}
                  aria-controls="panel2bh-content"
                  id="panel2bh-header"
                >
                  <Typography className="accordion-module-name">
                    Vouchers
                  </Typography>
                </AccordionSummary>
                <AccordionDetails sx={{ padding: 0 }}>
                  <List component="div">
                    <ListItem component="div">
                      <Button
                        sx={{ paddingLeft: "18px!important" }}
                        disableRipple
                        component={RouterLink}
                        className="submodule-name"
                        onClick={closeSidebar}
                        to="/accounts/voucher-entry"
                        startIcon={<TableChartTwoToneIcon />}
                      >
                        Voucher Entry
                      </Button>
                    </ListItem>
                    <ListItem component="div">
                      <Button
                        sx={{ paddingLeft: "18px!important" }}
                        disableRipple
                        component={RouterLink}
                        className="submodule-name"
                        onClick={closeSidebar}
                        to="/accounts/search-voucher"
                        startIcon={<TableChartTwoToneIcon />}
                      >
                        Search Voucher
                      </Button>
                    </ListItem>
                  </List>
                </AccordionDetails>
              </Accordion>
              {/* Reports  */}
              <Accordion
                className="accordion"
                expanded={expanded === "panel37"}
                onChange={handleChange("panel37")}
              >
                <AccordionSummary
                  expandIcon={<ExpandMoreIcon className="expand-icon" />}
                  aria-controls="panel2bh-content"
                  id="panel2bh-header"
                >
                  <Typography className="accordion-module-name">
                    Reports
                  </Typography>
                </AccordionSummary>
                <AccordionDetails sx={{ padding: 0 }}>
                  <List component="div">
                    <ListItem component="div">
                      <Button
                        sx={{ paddingLeft: "18px!important" }}
                        disableRipple
                        component={RouterLink}
                        className="submodule-name"
                        onClick={closeSidebar}
                        to="/accounts/trial-balance"
                        startIcon={<TableChartTwoToneIcon />}
                      >
                        Trial Balance
                      </Button>
                    </ListItem>
                    <ListItem component="div">
                      <Button
                        sx={{ paddingLeft: "18px!important" }}
                        disableRipple
                        component={RouterLink}
                        className="submodule-name"
                        onClick={closeSidebar}
                        to="/accounts/ledger"
                        startIcon={<TableChartTwoToneIcon />}
                      >
                        Ledger
                      </Button>
                    </ListItem>
                    <ListItem component="div">
                      <Button
                        sx={{ paddingLeft: "18px!important" }}
                        disableRipple
                        component={RouterLink}
                        className="submodule-name"
                        onClick={closeSidebar}
                        to="/"
                        startIcon={<TableChartTwoToneIcon />}
                      >
                        Group Ledger
                      </Button>
                    </ListItem>
                    <ListItem component="div">
                      <Button
                        sx={{ paddingLeft: "18px!important" }}
                        disableRipple
                        component={RouterLink}
                        className="submodule-name"
                        onClick={closeSidebar}
                        to="/accounts/cash-book"
                        startIcon={<TableChartTwoToneIcon />}
                      >
                        Cash Book
                      </Button>
                    </ListItem>
                    <ListItem component="div">
                      <Button
                        sx={{ paddingLeft: "18px!important" }}
                        disableRipple
                        component={RouterLink}
                        className="submodule-name"
                        onClick={closeSidebar}
                        to="/accounts/bank-book"
                        startIcon={<TableChartTwoToneIcon />}
                      >
                        Bank Book
                      </Button>
                    </ListItem>
                    <ListItem component="div">
                      <Button
                        sx={{ paddingLeft: "18px!important" }}
                        disableRipple
                        component={RouterLink}
                        className="submodule-name"
                        onClick={closeSidebar}
                        to="/accounts/subsidiary-ledger"
                        startIcon={<TableChartTwoToneIcon />}
                      >
                        Subsidiary Ledger
                      </Button>
                    </ListItem>
                    <ListItem component="div">
                      <Button
                        sx={{ paddingLeft: "18px!important" }}
                        disableRipple
                        component={RouterLink}
                        className="submodule-name"
                        onClick={closeSidebar}
                        to="/accounts/receive-payment"
                        startIcon={<TableChartTwoToneIcon />}
                      >
                        Receive Payment
                      </Button>
                    </ListItem>
                    <ListItem component="div">
                      <Button
                        sx={{ paddingLeft: "18px!important" }}
                        disableRipple
                        component={RouterLink}
                        className="submodule-name"
                        onClick={closeSidebar}
                        to="/accounts/income-statement"
                        startIcon={<TableChartTwoToneIcon />}
                      >
                        Income Statement
                      </Button>
                    </ListItem>
                    {/* <ListItem component="div">
                      <Button
                        sx={{ paddingLeft: "18px!important" }}
                        disableRipple
                        component={RouterLink}
                        className="submodule-name"
                        onClick={closeSidebar}
                        to="/"
                        startIcon={<TableChartTwoToneIcon />}
                      >
                        Fee Wise Members/Students
                      </Button>
                    </ListItem> */}
                    <ListItem component="div">
                      <Button
                        sx={{ paddingLeft: "18px!important" }}
                        disableRipple
                        component={RouterLink}
                        className="submodule-name"
                        onClick={closeSidebar}
                        to="/accounts/Balance-sheet"
                        startIcon={<TableChartTwoToneIcon />}
                      >
                        Balance Sheet
                      </Button>
                    </ListItem>
                    {/* <ListItem component="div">
                      <Button
                        sx={{ paddingLeft: "18px!important" }}
                        disableRipple
                        component={RouterLink}
                        className="submodule-name"
                        onClick={closeSidebar}
                        to="/"
                        startIcon={<TableChartTwoToneIcon />}
                      >
                        Balance Sheet Notes
                      </Button>
                    </ListItem> */}
                  </List>
                </AccordionDetails>
              </Accordion>
              {/* Chart of account  */}
              <Accordion
                className="accordion"
                expanded={expanded === "panel38"}
                onChange={handleChange("panel38")}
              >
                <AccordionSummary
                  expandIcon={<ExpandMoreIcon className="expand-icon" />}
                  aria-controls="panel2bh-content"
                  id="panel2bh-header"
                >
                  <Typography className="accordion-module-name">
                    Chart of Accounts
                  </Typography>
                </AccordionSummary>
                <AccordionDetails sx={{ padding: 0 }}>
                  <List component="div">
                    <ListItem component="div">
                      <Button
                        sx={{ paddingLeft: "18px!important" }}
                        disableRipple
                        component={RouterLink}
                        className="submodule-name"
                        onClick={closeSidebar}
                        to="/"
                        startIcon={<TableChartTwoToneIcon />}
                      >
                        Chart of Accounts
                      </Button>
                    </ListItem>
                    <ListItem component="div">
                      <Button
                        sx={{ paddingLeft: "18px!important" }}
                        disableRipple
                        component={RouterLink}
                        className="submodule-name"
                        onClick={closeSidebar}
                        to="/"
                        startIcon={<TableChartTwoToneIcon />}
                      >
                        Account Group Entry
                      </Button>
                    </ListItem>
                    <ListItem component="div">
                      <Button
                        sx={{ paddingLeft: "18px!important" }}
                        disableRipple
                        component={RouterLink}
                        className="submodule-name"
                        onClick={closeSidebar}
                        to="/"
                        startIcon={<TableChartTwoToneIcon />}
                      >
                        Day Closing
                      </Button>
                    </ListItem>
                  </List>
                </AccordionDetails>
              </Accordion>
              {/* Bank */}
              <Accordion
                className="accordion"
                expanded={expanded === "panel39"}
                onChange={handleChange("panel39")}
              >
                <AccordionSummary
                  expandIcon={<ExpandMoreIcon className="expand-icon" />}
                  aria-controls="panel2bh-content"
                  id="panel2bh-header"
                >
                  <Typography className="accordion-module-name">
                    Bank
                  </Typography>
                </AccordionSummary>
                <AccordionDetails sx={{ padding: 0 }}>
                  <List component="div">
                    <ListItem component="div">
                      <Button
                        sx={{ paddingLeft: "18px!important" }}
                        disableRipple
                        component={RouterLink}
                        className="submodule-name"
                        onClick={closeSidebar}
                        to="/accounts/bank/list"
                        startIcon={<TableChartTwoToneIcon />}
                      >
                        Bank
                      </Button>
                    </ListItem>
                    <ListItem component="div">
                      <Button
                        sx={{ paddingLeft: "18px!important" }}
                        disableRipple
                        component={RouterLink}
                        className="submodule-name"
                        onClick={closeSidebar}
                        to="/accounts/bank/cheque-register-entry"
                        startIcon={<TableChartTwoToneIcon />}
                      >
                        Cheque Register Entry
                      </Button>
                    </ListItem>
                    <ListItem component="div">
                      <Button
                        sx={{ paddingLeft: "18px!important" }}
                        disableRipple
                        component={RouterLink}
                        className="submodule-name"
                        onClick={closeSidebar}
                        to="/"
                        startIcon={<TableChartTwoToneIcon />}
                      >
                        Cheque Register Summary
                      </Button>
                    </ListItem>
                    <ListItem component="div">
                      <Button
                        sx={{ paddingLeft: "18px!important" }}
                        disableRipple
                        component={RouterLink}
                        className="submodule-name"
                        onClick={closeSidebar}
                        to="/"
                        startIcon={<TableChartTwoToneIcon />}
                      >
                        Bank Reconciliation
                      </Button>
                    </ListItem>
                    <ListItem component="div">
                      <Button
                        sx={{ paddingLeft: "18px!important" }}
                        disableRipple
                        component={RouterLink}
                        className="submodule-name"
                        onClick={closeSidebar}
                        to="/"
                        startIcon={<TableChartTwoToneIcon />}
                      >
                        Bank Reconciliation List
                      </Button>
                    </ListItem>
                  </List>
                </AccordionDetails>
              </Accordion>
            </List>
          )}
        </SubMenuWrapper>
      </List>
    </>
  );
};

export default AccountsSideBar;
