import { TextField } from "@mui/material";
type IProps = {
  register: any;
  label: string;
  name: string;
  type: string;
};

const CommonTexField = ({ register, label, name, type }: IProps) => {
  return (
    <TextField
      size="small"
      fullWidth
      required
      id={label}
      label={label}
      {...register(name)}
      type={type}
      variant="outlined"
    />
  );
};

export default CommonTexField;
