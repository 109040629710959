import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { HttpClient } from "src/Helpers/http/http";
import { url } from "src/Helpers/utils/Constant";
import { singleMoneyReceipt } from "src/Types/module/common/commonTypes";
import { Helmet } from "react-helmet-async";
import { Container } from "@mui/material";
import GlobalLoader from "src/components/SuspenseLoader/GlobalLoader";
import CardMoneyReceipt from "../components/CardMoneyReceipt";

const SingleMoneyReceipt = () => {
  const { id } = useParams();
  const [singleMoneyReceipt, setSingleMoneyReceipt] =
    useState<singleMoneyReceipt>({} as singleMoneyReceipt);
  const [loading, setLoading] = useState<Boolean>(false);
  // const [editMode, setEditMode] = useState(false);

  useEffect(() => {
    setLoading(true);
    (async function () {
      try {
        const res: any = await HttpClient.get(
          `${url}/api/atab/money-receipt/for-admin/${id}`
        );

        if (res.success) {
          setSingleMoneyReceipt(res.data);
          setLoading(false);
        } else {
          setLoading(false);
        }
      } catch (error) {
        setLoading(false);
      }
    })();
  }, [id]);

  return (
    <div>
      <Helmet>
        <title>Atab - Single money receipt</title>
      </Helmet>

      <Container sx={{ mt: 5 }} maxWidth="lg">
        {!loading ? (
          <CardMoneyReceipt
            singleInvoiceInfo={singleMoneyReceipt}
            setSingleInvoiceInfo={setSingleMoneyReceipt}
          />
        ) : (
          <GlobalLoader />
        )}
      </Container>
    </div>
  );
};

export default SingleMoneyReceipt;
