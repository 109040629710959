import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import {
  Box,
  Button,
  Grid,
  TextField,
  Select,
  TextareaAutosize,
} from "@mui/material";
import { LoadingButton } from "@mui/lab";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import AutoSearchInput from "src/common/AutoSearchInput";
import {
  IAccountHead,
  IAccountHeadResponse,
  IBankHead,
  IBankHeadResponse,
} from "../types/vouchersTypes";
import { HttpClient } from "src/Helpers/http/http";

const CreateVoucherEntry = () => {
  const { register, handleSubmit, watch } = useForm();
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [accountHead, seTAccountHead] = useState<IAccountHead[]>([]);
  const [bankHead, setBankHead] = useState<IBankHead[]>([]);
  const [searchAutoInputValue, setSearchAutoInputValue] = useState<number>();
  const onSubmit = async (inputs: any) => {
    // setIsLoading(true);
    console.log(inputs);
  };

  const voucherType = watch("voucher_type") || "Payment";
  const payType = watch("pay_type") || "Cash";
  const transactionType = watch("transaction_type") || "Debit";
  const company = watch("company") || "ATAB Head Office";
  const getBankHead = watch("bank_head");

  useEffect(() => {
    const fetchAccountHead = HttpClient.get<IAccountHeadResponse>(
      `/api/atab/voucher/account-head/list?company=${company}&voucher_type=${voucherType}`
    );
    const fetchBankHead = HttpClient.get<IBankHeadResponse>(
      `/api/atab/voucher/account/bank-head/list?company=${company}`
    );

    Promise.all([fetchAccountHead, fetchBankHead])
      .then(([accountHeadRes, bankHeadRes]) => {
        if (accountHeadRes.success) {
          seTAccountHead(accountHeadRes.data);
        } else {
          seTAccountHead([]);
        }
        if (bankHeadRes.success) {
          setBankHead(bankHeadRes.data);
        } else {
          setBankHead([]);
        }
      })
      .catch((error) => {
        console.error("Error fetching data:", error);
        seTAccountHead([]);
        setBankHead([]);
      });
  }, [company, voucherType]);

  // useEffect(() => {
  //   (async function () {
  //     const res: IAccountHeadResponse = await HttpClient.get(
  //       `/api/atab/voucher/account-head/list?company=${company}&voucher_type=${voucherType}`
  //     );
  //     if (res.success) {
  //       seTAccountHead(res.data);
  //     } else {
  //       seTAccountHead([]);
  //     }
  //   })();
  // }, [company, voucherType]);
  // useEffect(() => {
  //   (async function () {
  //     const res: IBankHeadResponse = await HttpClient.get(
  //       `/api/atab/voucher/account/bank-head/list?company=${company}`
  //     );
  //     if (res.success) {
  //       setBankHead(res.data);
  //     } else {
  //       setBankHead([]);
  //     }
  //   })();
  // }, [company]);

  return (
    <Box sx={{ p: 2 }}>
      <Box
        component="form"
        onSubmit={handleSubmit(onSubmit)}
        sx={{
          mt: 1,
        }}
      >
        <Grid container spacing={2}>
          <Grid item xs={12} md={6} lg={6}>
            <FormControl fullWidth>
              <InputLabel id="demo-simple-select-label">
                Select Company
              </InputLabel>
              <Select
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                value={company}
                label="Select Company"
                {...register("company")}
              >
                <MenuItem value={"ATAB Head Office"}>ATAB Head Office</MenuItem>
                <MenuItem value={"ATTI"}>ATTI</MenuItem>
              </Select>
            </FormControl>
          </Grid>
          <Grid item xs={12} md={6} lg={6}>
            <FormControl fullWidth>
              <InputLabel id="demo-simple-select-label">
                Select Voucher Type
              </InputLabel>
              <Select
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                value={voucherType}
                label="Select Voucher Type"
                {...register("voucher_type")}
              >
                <MenuItem value={"Payment"}>Payment</MenuItem>
                <MenuItem value={"Receive"}>Receive</MenuItem>
                <MenuItem value={"Journal"}>Journal</MenuItem>
                <MenuItem value={"Contra"}>Contra</MenuItem>
              </Select>
            </FormControl>
          </Grid>

          {voucherType === "Journal" || voucherType === "Contra" ? (
            <Grid item xs={12} md={6} lg={6}>
              <FormControl fullWidth>
                <InputLabel id="demo-simple-select-label">
                  Select Transaction Type
                </InputLabel>
                <Select
                  labelId="demo-simple-select-label"
                  id="demo-simple-select"
                  value={transactionType}
                  label=" Select Transaction Type"
                  {...register("transaction_type")}
                >
                  <MenuItem value={"Debit"}>Debit</MenuItem>
                  <MenuItem value={"Credit"}>Credit</MenuItem>
                </Select>
              </FormControl>
            </Grid>
          ) : (
            <Grid item xs={12} md={6} lg={6}>
              <FormControl fullWidth>
                <InputLabel id="demo-simple-select-label">
                  Select Pay Type
                </InputLabel>
                <Select
                  labelId="demo-simple-select-label"
                  id="demo-simple-select"
                  value={payType}
                  label="Select Pay Type"
                  {...register("pay_type")}
                >
                  <MenuItem value={"Cash"}>Cash</MenuItem>
                  <MenuItem value={"Bank"}>Bank</MenuItem>
                </Select>
              </FormControl>
            </Grid>
          )}

          <Grid item xs={12} md={6}>
            <AutoSearchInput
              getOptionLabel={(accountHead) => accountHead?.AccountHead}
              label={"Select Account Head"}
              required={true}
              options={accountHead}
              setSearchAutoInputValue={setSearchAutoInputValue}
              searchAutoInputValue={searchAutoInputValue}
            />
          </Grid>
          <Grid item xs={12} md={6} lg={6}>
            <TextField
              fullWidth
              required
              id="Amount"
              label="Amount"
              {...register("amount")}
              type="number"
              variant="outlined"
            />
          </Grid>

          {/* bank Information input  */}

          {payType === "Bank" ||
          voucherType === "Journal" ||
          voucherType === "Contra" ? (
            <>
              <Grid item xs={12} md={6} lg={6}>
                <TextField
                  fullWidth
                  id="bank_name"
                  label="Bank Name"
                  {...register("bank_name")}
                  type="text"
                  variant="outlined"
                />
              </Grid>
              <Grid item xs={12} md={6} lg={6}>
                <FormControl fullWidth>
                  <InputLabel id="demo-simple-select-label">
                    Select Bank Head
                  </InputLabel>
                  <Select
                    labelId="demo-simple-select-label"
                    id="demo-simple-select"
                    value={getBankHead}
                    label="Select Bank Head"
                    {...register("bank_head")}
                  >
                    {bankHead?.map((sBank) => (
                      <MenuItem value={sBank.id} key={sBank.id}>
                        {sBank.Name}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </Grid>
              <Grid item xs={12} md={6} lg={6}>
                <TextField
                  fullWidth
                  id="cheque_date"
                  // defaultValue={
                  //   getBankHead ? moment(new Date()).format("MM/DD/YYYY") : ""
                  // }
                  label="Cheque Date"
                  {...register("cheque_date")}
                  type="date"
                  InputLabelProps={{ shrink: true }}
                  variant="outlined"
                />
              </Grid>
              <Grid item xs={12} md={6} lg={6}>
                <TextField
                  fullWidth
                  id="cheque_no"
                  label="Cheque no"
                  {...register("cheque_no")}
                  type="text"
                  variant="outlined"
                />
              </Grid>
              <Grid item xs={12} md={6} lg={6}>
                <TextField
                  fullWidth
                  id="branch"
                  label="Branch"
                  {...register("branch")}
                  type="text"
                  variant="outlined"
                />
              </Grid>
              <Grid item xs={12} md={6} lg={6}>
                <TextField
                  fullWidth
                  id="pay_to"
                  label="Pay TO"
                  {...register("pay_to")}
                  type="text"
                  variant="outlined"
                />
              </Grid>
            </>
          ) : (
            ""
          )}

          <Grid item xs={6}>
            <TextareaAutosize
              placeholder="Description"
              minRows={5}
              id="outlined-required"
              {...register("description")}
              style={{ width: "100%", padding: "10px" }}
            />
          </Grid>
        </Grid>
        <Box textAlign={"end"}>
          {isLoading ? (
            <LoadingButton
              loading={isLoading}
              fullWidth
              color="secondary"
              disableElevation
              sx={{
                mt: 3,
                mb: 2,
                py: 1,
              }}
              variant="contained"
            >
              Loading...
            </LoadingButton>
          ) : (
            <Button
              type="submit"
              variant="contained"
              disableElevation
              sx={{
                mt: 3,
                mb: 2,
                py: 1,
              }}
            >
              Add
            </Button>
          )}
        </Box>
      </Box>
    </Box>
  );
};

export default CreateVoucherEntry;
