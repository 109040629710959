import {
  Box,
  Button,
  Grid,
  TextField,
  Select,
  TextareaAutosize,
  Autocomplete,
  Chip,
} from "@mui/material";
import { useForm } from "react-hook-form";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import { useEffect, useState } from "react";
import { IuserMemberList } from "src/Types/module/invoice/invoiceTypes";
import { debounce } from "lodash";
import axios from "axios";
import { url } from "src/Helpers/utils/Constant";
import { ISingleMemberInvoice } from "../types/moneyReceiptTypes";
import { HttpClient } from "src/Helpers/http/http";
import Toaster from "src/Toaster/Toaster";
import { LoadingButton } from "@mui/lab";

interface ISingleMember {
  user_member_id: number;
  user_member_company_name: string;
  user_member_representative_name: string;
}
const CreateMoneyReceipt = ({ setRefresh, handelClose }: any) => {
  const { register, handleSubmit, watch } = useForm();
  const [allMember, setAllMember] = useState<IuserMemberList[]>([]);
  const [member, setMember] = useState<ISingleMember | null>(null);
  const [companyName, seTCompanyName] = useState<string>("");
  const [memberInvoices, setMemberInvoices] = useState<ISingleMemberInvoice[]>(
    []
  );
  const [invoices, setInvoices] = useState<ISingleMemberInvoice[]>([]);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    (async function () {
      try {
        const { data } = await axios.get(
          `${url}/api/atab/user-member/company-name/get-all/status-or-all/all?company_name=${companyName}`
        );
        if (data.success) {
          setAllMember(data.data);
        }
      } catch (error) {}
    })();
  }, [companyName]);

  useEffect(() => {
    if (member?.user_member_id) {
      (async function () {
        try {
          const { data } = await axios.get(
            `${url}/api/atab/invoice/get/all/by/user-member/for-admin/status/${member?.user_member_id}/pending`
          );

          if (data.success) {
            setMemberInvoices(data.data);
          }
        } catch (error) {}
      })();
    }
  }, [member]);

  const onSubmit = async (inputs: any) => {
    setLoading(true);
    const submitData = { ...inputs };
    submitData.member_id = member.user_member_id;

    if (invoices?.length) {
      const invoiceIds = invoices?.map(
        (invoice) => invoice.atab_payment_invoice_id
      );
      submitData.invoice_ids = invoiceIds;
    }

    try {
      const res: any = await HttpClient.post(
        `${url}/api/atab/money-receipt`,
        submitData
      );
      if (res.success) {
        Toaster().fire({
          icon: "success",
          title: res.message,
        });
        setLoading(false);
        handelClose();
        setRefresh((prv) => !prv);
      } else {
        setLoading(false);
      }
    } catch (err: any) {
      Toaster().fire({
        icon: "error",
        title: `${err.response?.data?.message}`,
      });
      setLoading(false);
    }
  };

  const specificInvoice = watch("specific_type") || "all";
  const payType = watch("payment_type") || "cash";

  const totalAmount = invoices?.reduce(
    (acc, curr) => acc + parseFloat(curr.atab_payment_invoice_grand_total),
    0
  );
  const allAmount = memberInvoices?.reduce(
    (acc, curr) => acc + parseFloat(curr.atab_payment_invoice_grand_total),
    0
  );

  const handelInputChange = debounce((value: string) => {
    seTCompanyName(value);
  }, 500);

  return (
    <div>
      <Box sx={{ p: 2 }}>
        <Box
          component="form"
          onSubmit={handleSubmit(onSubmit)}
          sx={{
            mt: 1,
          }}
        >
          <Grid container spacing={2}>
            <Grid xs={12} md={6} lg={6} item>
              <Autocomplete
                value={member}
                onChange={(_event: any, newValue: any) => {
                  setMember(newValue);
                }}
                isOptionEqualToValue={(option, value) =>
                  option.title === value.title
                }
                loading={true}
                id="controllable-states-demo"
                options={allMember}
                onInputChange={(_event, newInputValue) => {
                  handelInputChange(newInputValue);
                }}
                getOptionLabel={(option) => option.user_member_company_name}
                renderTags={(value: readonly any[], getTagProps) =>
                  value.map((option: any, index: number) => (
                    <Chip
                      variant="filled"
                      label={option}
                      {...getTagProps({ index })}
                    />
                  ))
                }
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label="Select member"
                    required
                    InputProps={{
                      ...params.InputProps,
                      endAdornment: <>{params.InputProps.endAdornment}</>,
                    }}
                  />
                )}
              />
            </Grid>
            <Grid item xs={12} md={6} lg={6}>
              <FormControl fullWidth>
                <InputLabel id="demo-simple-select-label">
                  Payment to specific invoice
                </InputLabel>
                <Select
                  labelId="demo-simple-select-label"
                  id="demo-simple-select"
                  value={specificInvoice}
                  label="Payment to specific invoice"
                  {...register("specific_type")}
                >
                  <MenuItem value={"all"}>All</MenuItem>
                  <MenuItem value={"specific"}>Specific</MenuItem>
                </Select>
              </FormControl>
            </Grid>

            {specificInvoice === "specific" && (
              <>
                <Grid xs={12} md={6} lg={6} item>
                  <Autocomplete
                    multiple
                    value={invoices}
                    onChange={(_event: any, newValue: any) => {
                      setInvoices(newValue);
                    }}
                    isOptionEqualToValue={(option, value) =>
                      option.atab_payment_invoice_id ===
                      value.atab_payment_invoice_id
                    }
                    loading={true}
                    id="controllable-states-demo"
                    options={memberInvoices}
                    onInputChange={(_event, newInputValue) => {
                      handelInputChange(newInputValue);
                    }}
                    getOptionLabel={
                      (option: ISingleMemberInvoice) =>
                        option.atab_payment_invoice_no || "No Invoice Number" // Provide a fallback label
                    }
                    renderTags={(
                      value: readonly ISingleMemberInvoice[],
                      getTagProps
                    ) =>
                      value.map(
                        (option: ISingleMemberInvoice, index: number) => {
                          const { key, ...tagProps } = getTagProps({ index });
                          return (
                            <Chip
                              key={key} // Pass key directly
                              variant="filled"
                              label={
                                option.atab_payment_invoice_no ||
                                "No Invoice Number"
                              } // Provide a fallback label
                              {...tagProps} // Spread the rest of the props
                            />
                          );
                        }
                      )
                    }
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        label="Select invoices"
                        InputProps={{
                          ...params.InputProps,
                          endAdornment: <>{params.InputProps.endAdornment}</>,
                        }}
                      />
                    )}
                  />
                </Grid>
              </>
            )}
            <Grid item xs={12} md={6} lg={6}>
              <FormControl fullWidth>
                <InputLabel id="demo-simple-select-label">
                  Select payment type
                </InputLabel>
                <Select
                  labelId="demo-simple-select-label"
                  id="demo-simple-select"
                  value={payType}
                  label="Select payment type"
                  {...register("payment_type")}
                >
                  <MenuItem value={"cash"}>Cash</MenuItem>
                  <MenuItem value={"bank"}>Bank</MenuItem>
                  <MenuItem value={"cheque"}>Cheque</MenuItem>
                </Select>
              </FormControl>
            </Grid>
            <Grid item xs={12} md={6} lg={6}>
              <TextField
                fullWidth
                required
                id="Amount"
                value={specificInvoice === "specific" ? totalAmount : allAmount}
                label="Amount"
                disabled
                // {...register("amount")}
                type="number"
                variant="outlined"
              />
            </Grid>

            <Grid item xs={12}>
              <TextareaAutosize
                placeholder="Description"
                minRows={5}
                id="outlined-required"
                {...register("description")}
                style={{
                  width: "100%",
                  padding: "10px",
                  border: "1px solid black",
                  borderRadius: "5px",
                }}
              />
            </Grid>
          </Grid>
          <Box textAlign={"center"} mt={3}>
            {loading ? (
              <LoadingButton
                loading={loading}
                fullWidth
                color="secondary"
                disableElevation
                sx={{
                  mt: 3,
                  mb: 2,
                  py: 1,
                }}
                variant="contained"
              >
                Loading...
              </LoadingButton>
            ) : (
              <Button
                type="submit"
                variant="contained"
                disableElevation
                sx={{
                  mt: 3,
                  mb: 2,
                  py: 1,
                }}
              >
                Create
              </Button>
            )}{" "}
          </Box>
        </Box>
      </Box>
    </div>
  );
};

export default CreateMoneyReceipt;
