import React, { useState, useRef } from "react";
import {
  Divider,
  Box,
  FormControl,
  InputLabel,
  Card,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TablePagination,
  TableRow,
  TableContainer,
  Select,
  MenuItem,
  CardHeader,
  TextField,
  Container,
  Grid,
  Typography,
  Button,
  Tooltip,
  IconButton,
} from "@mui/material";
import { TDate } from "../types/vouchersTypes";
import { TableLoader, TableNotFound } from "src/Utils/TableLoader";
import PrintIcon from "@mui/icons-material/Print";
import PrintVoucherComponents from "../components/PrintVoucherComponents";
import { useReactToPrint } from "react-to-print";
import { DatePicker } from "@mui/x-date-pickers";
const SearchVoucher = () => {
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [date, setDate] = useState<TDate>({ startDate: null, endDate: null });
  const [allVoucher, setAllVoucher] = useState([]);

  const componentRef = useRef(null);

  const handlePrint = useReactToPrint({
    content: () => componentRef.current,
    documentTitle: `voucher_report`,
  });

  return (
    <Container sx={{ mt: 3 }} maxWidth="xl">
      <Grid
        container
        direction="row"
        justifyContent="center"
        alignItems="stretch"
        spacing={3}
      >
        <Grid item xs={12}>
          <Card sx={{ p: 2 }}>
            <Grid container spacing={{ xs: 2, md: 3 }} alignItems={"center"}>
              <Grid item xs={12} md={3}>
                <FormControl fullWidth>
                  <InputLabel id="demo-simple-select-label">Company</InputLabel>
                  <Select
                    labelId="demo-simple-select-label"
                    id="demo-simple-select"
                    value={"ATAB Head Office"}
                    size="small"
                    label="Company"
                  >
                    <MenuItem value={"ATAB Head Office"}>
                      ATAB Head Office
                    </MenuItem>
                    <MenuItem value={"ATTI"}>ATTI</MenuItem>
                  </Select>
                </FormControl>
              </Grid>
              <Grid item xs={12} md={3}>
                <FormControl fullWidth>
                  <InputLabel id="demo-simple-select-label">
                    Voucher Type
                  </InputLabel>
                  <Select
                    labelId="demo-simple-select-label"
                    id="demo-simple-select"
                    value={"Payment"}
                    size="small"
                    label="Voucher Type"
                  >
                    <MenuItem value={"Payment"}>Payment</MenuItem>
                    <MenuItem value={"Receive"}>Receive</MenuItem>
                    <MenuItem value={"Journal"}>Journal</MenuItem>
                    <MenuItem value={"Contra"}>Contra</MenuItem>
                  </Select>
                </FormControl>
              </Grid>
              <Grid item xs={12} md={3}>
                <FormControl fullWidth>
                  <InputLabel id="demo-simple-select-label">
                    Transaction Type
                  </InputLabel>
                  <Select
                    size="small"
                    labelId="demo-simple-select-label"
                    id="demo-simple-select"
                    value={"Debit"}
                    label="Transaction Type"
                  >
                    <MenuItem value={"Debit"}>Debit</MenuItem>
                    <MenuItem value={"Credit"}>Credit</MenuItem>
                  </Select>
                </FormControl>
              </Grid>
              <Grid item xs={12} md={3}>
                <FormControl fullWidth>
                  <InputLabel id="demo-simple-select-label">
                    Pay Type
                  </InputLabel>
                  <Select
                    size="small"
                    labelId="demo-simple-select-label"
                    id="demo-simple-select"
                    value={"Cash"}
                    label="Pay Type"
                  >
                    <MenuItem value={"Cash"}>Cash</MenuItem>
                    <MenuItem value={"Bank"}>Bank</MenuItem>
                  </Select>
                </FormControl>
              </Grid>

              <Grid
                item
                xs={12}
                md={3}
                sx={{
                  display: "flex",
                  alignItems: "center",
                  gap: 1,
                }}
              >
                <Typography
                  fontWeight={"bold"}
                  variant="h6"
                  component="h6"
                  gutterBottom
                >
                  From
                </Typography>

                <DatePicker
                  label="Start Date"
                  value={date.startDate}
                  onChange={(e: any) => e && setDate({ ...date, startDate: e })}
                />
              </Grid>
              <Grid
                item
                xs={12}
                md={3}
                sx={{
                  display: "flex",
                  alignItems: "center",
                  gap: 1,
                }}
              >
                <Typography
                  fontWeight={"bold"}
                  variant="h6"
                  component="h6"
                  gutterBottom
                >
                  To
                </Typography>

                <DatePicker
                  label="Start Date"
                  value={date.startDate}
                  onChange={(e: any) => e && setDate({ ...date, startDate: e })}
                />
              </Grid>
              <Grid item xs={12} md={3} sx={{ textAlign: "end" }}>
                <TextField
                  fullWidth
                  size="small"
                  id="outlined-basic"
                  label="Voucher No"
                  variant="outlined"
                />
              </Grid>
              <Grid item xs={12} md={3} sx={{ textAlign: "end" }}>
                <Button variant="contained" size="small">
                  Search
                </Button>
              </Grid>
            </Grid>
          </Card>
        </Grid>
        <Grid item xs={12}>
          <Card>
            <CardHeader
              action={
                <Box display="flex" justifyContent="space-between" gap={2}>
                  <Tooltip title="Print voucher report">
                    <IconButton onClick={handlePrint}>
                      <PrintIcon />
                    </IconButton>
                  </Tooltip>
                </Box>
              }
              title={`Voucher List`}
            />
            <Divider />
            <TableContainer>
              <Table>
                <TableHead>
                  <TableRow>
                    <TableCell>Voucher NO</TableCell>
                    <TableCell>Account Head</TableCell>
                    <TableCell>Description</TableCell>
                    <TableCell>Date</TableCell>
                    <TableCell>Debit</TableCell>
                    <TableCell>Credit</TableCell>
                    <TableCell>View</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {isLoading ? (
                    <TableLoader />
                  ) : allVoucher.length ? (
                    <>
                      {allVoucher.map((sVoucher) => {
                        return (
                          <h1>maklflk</h1>
                          // <CommonMemberTableRow
                          //   key={allMembers.user_member_id}
                          //   allMembers={allMembers}
                          // />
                        );
                      })}
                    </>
                  ) : (
                    <TableNotFound />
                  )}
                </TableBody>
              </Table>
            </TableContainer>
            {/* <Box p={2}>
              <TablePagination
                component="div"
                count={total}
                onPageChange={(_event: any, newPage: number) =>
                  setSearchParams({ page: newPage.toString() })
                }
                onRowsPerPageChange={(event) =>
                  setLimit(parseInt(event.target.value))
                }
                page={page && page}
                rowsPerPage={limit}
                rowsPerPageOptions={[25, 50, 75, 100]}
              />
            </Box> */}
          </Card>
        </Grid>
      </Grid>

      <Box display={"none"}>
        <PrintVoucherComponents
          componentRef={componentRef}
          company={"ATAB Head Office"}
          voucherType={"Payment"}
          date={date}
        />
      </Box>
    </Container>
  );
};

export default SearchVoucher;
