import { Visibility } from "@mui/icons-material";
import {
  IconButton,
  TableCell,
  TableRow,
  Tooltip,
  useTheme,
} from "@mui/material";
import moment from "moment";
import { Link } from "react-router-dom";
import { getStatusLabel } from "src/Utils/Combined";
import { IMoneyReceipt } from "src/Types/module/common/commonTypes";
import { IInvoice } from "./types/invoiceType";

type IProps = {
  sInvoice: IInvoice;
  index: number;
};

const InvoiceTableRow = ({ sInvoice: invoiceData, index }: IProps) => {
  const theme = useTheme();

  return (
    <>
      <TableRow hover>
        <TableCell>{index + 1}</TableCell>

        <TableCell>
          {moment(invoiceData.atab_payment_invoice_issue_date).format(
            "DD/MM/YYYY"
          )}
        </TableCell>
        <TableCell>{invoiceData.atab_payment_invoice_no}</TableCell>
        <TableCell>
          <Link to={`/member-list/${invoiceData.user_member_id}`}>
            {invoiceData.user_member_company_name
              .toLowerCase()
              .split(" ")
              .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
              .join(" ")}
          </Link>
        </TableCell>
        <TableCell>{invoiceData.atab_payment_invoice_grand_total}</TableCell>

        <TableCell>
          {getStatusLabel(invoiceData.atab_payment_invoice_status)}
        </TableCell>
        <TableCell>
          <Tooltip title="View" arrow>
            <Link to={`${invoiceData.atab_payment_invoice_id}`}>
              <IconButton
                sx={{
                  "&:hover": {
                    background: theme.colors.primary.lighter,
                  },
                  color: theme.palette.primary.main,
                }}
                color="inherit"
                size="small"
              >
                <Visibility fontSize="small" />
              </IconButton>
            </Link>
          </Tooltip>
        </TableCell>
      </TableRow>
    </>
  );
};

export default InvoiceTableRow;
