import React, { useState } from "react";
import { LoadingButton } from "@mui/lab";
import { useForm } from "react-hook-form";
import { Box, Button, Grid, Select } from "@mui/material";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import CommonTexField from "src/common/CommonTexField";

export const CreateBank = () => {
  const { register, handleSubmit, watch } = useForm();
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const onSubmit = async (inputs: any) => {
    // setIsLoading(true);
    console.log(inputs);
  };

  const accountType = watch("account_type");

  return (
    <Box sx={{ p: 2 }}>
      <Box component="form" onSubmit={handleSubmit(onSubmit)}>
        <Grid container spacing={2}>
          <Grid item xs={12} md={4} lg={4}>
            <CommonTexField
              label={"Name"}
              register={register}
              name={"name"}
              type={"text"}
            />
          </Grid>
          <Grid item xs={12} md={4} lg={4}>
            <CommonTexField
              label={"Branch Name"}
              register={register}
              name={"branch-name"}
              type={"text"}
            />
          </Grid>
          <Grid item xs={12} md={4} lg={4}>
            <CommonTexField
              label={"Address"}
              register={register}
              name={"address"}
              type={"text"}
            />
          </Grid>
          <Grid item xs={12} md={4} lg={4}>
            <CommonTexField
              label={"Fax"}
              register={register}
              name={"fax"}
              type={"text"}
            />
          </Grid>
          <Grid item xs={12} md={4} lg={4}>
            <CommonTexField
              label={"Phone No"}
              register={register}
              name={"phone-no"}
              type={"number"}
            />
          </Grid>
          <Grid item xs={12} md={4} lg={4}>
            <CommonTexField
              label={"Email"}
              register={register}
              name={"email"}
              type={"email"}
            />
          </Grid>
          <Grid item xs={12} md={4} lg={4}>
            <CommonTexField
              label={"Account no"}
              register={register}
              name={"account-no"}
              type={"text"}
            />
          </Grid>
          <Grid item xs={12} md={4} lg={4}>
            <CommonTexField
              label={"Signatories"}
              register={register}
              name={"signatories"}
              type={"text"}
            />
          </Grid>
          <Grid item xs={12} md={4} lg={4}>
            <FormControl fullWidth>
              <InputLabel id="demo-simple-select-label">
                Account Type
              </InputLabel>
              <Select
                size="small"
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                value={accountType}
                label="Voucher Type"
                {...register("account_type")}
              >
                <MenuItem value={"None"}>None</MenuItem>
                <MenuItem value={"Savings"}>Savings</MenuItem>
                <MenuItem value={"Current"}>Current</MenuItem>
                <MenuItem value={"STD"}>STD</MenuItem>
              </Select>
            </FormControl>
          </Grid>
        </Grid>
        <Box textAlign={"end"}>
          {isLoading ? (
            <LoadingButton
              loading={isLoading}
              fullWidth
              color="secondary"
              disableElevation
              sx={{
                mt: 3,
                mb: 2,
                py: 1,
              }}
              variant="contained"
            >
              Loading...
            </LoadingButton>
          ) : (
            <Button
              type="submit"
              variant="contained"
              disableElevation
              sx={{
                mt: 3,
                mb: 2,
                py: 1,
              }}
            >
              Create
            </Button>
          )}
        </Box>
      </Box>
    </Box>
  );
};
