import { FC, ChangeEvent, useState } from 'react';
import {
  Divider,
  Box,
  Card,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TablePagination,
  TableRow,
  TableContainer,
  CardHeader,
  useTheme,
  useMediaQuery,
  Dialog,
  DialogActions,
  DialogContent,
  Typography,
  Button,
} from '@mui/material';

import PaymentableItemTableRow from './PaymentableItemTableRow';
import { IPaymentableItemList } from 'src/Types/module/paymentableItem/itemList';
import { applyPagination } from 'src/Utils/CommonAllShowUtils';
import { TableLoader, TableNotFound } from 'src/Utils/TableLoader';
import CreatePaymentableItem from '../CreatePaymentableItem/Index';

interface RecentOrdersTableProps {
  className?: string;
  allInvoice: IPaymentableItemList[];
  setAllPayments: Function;
  setReload: (value: Boolean) => void;

  loading: Boolean;
}

const PaymentableItemTable: FC<RecentOrdersTableProps> = ({
  allInvoice,
  setAllPayments,
  setReload,
  loading,
}) => {
  const [page, setPage] = useState<number>(0);
  const [limit, setLimit] = useState<number>(25);

  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down('md'));

  // Modal
  const [open, setOpen] = useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  const handlePageChange = (event: any, newPage: number): void => {
    setPage(newPage);
  };

  const handleLimitChange = (event: ChangeEvent<HTMLInputElement>): void => {
    setLimit(parseInt(event.target.value));
  };

  const paginatedInvoice = applyPagination(allInvoice, page, limit);

  return (
    <Card>
      <CardHeader
        title='All Invoice Item list'
        action={
          <Button
            onClick={() => handleOpen()}
            variant='contained'
            sx={{ mr: 1 }}
          >
            Create Invoice Item
          </Button>
        }
      />
      <Divider />
      <TableContainer>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>ID</TableCell>
              <TableCell>Item name</TableCell>
              <TableCell>Item BDT amount</TableCell>
              <TableCell>Item USD amount</TableCell>
              <TableCell>Actions</TableCell>
            </TableRow>
          </TableHead>

          <TableBody>
            {loading ? (
              <TableLoader />
            ) : paginatedInvoice.length ? (
              <>
                {paginatedInvoice.map((allInvoice: any) => {
                  return (
                    <PaymentableItemTableRow
                      key={allInvoice.atab_paymentable_item_id}
                      invoiceData={allInvoice}
                      setReload={setReload}
                    />
                  );
                })}
              </>
            ) : (
              <TableNotFound />
            )}
          </TableBody>
        </Table>
      </TableContainer>
      <Box p={2}>
        <TablePagination
          component='div'
          count={allInvoice.length}
          onPageChange={handlePageChange}
          onRowsPerPageChange={handleLimitChange}
          page={page}
          rowsPerPage={limit}
          rowsPerPageOptions={[25, 50, 75, 100]}
        />
      </Box>
      {/* Modal */}
      <Dialog
        fullScreen={fullScreen}
        open={open}
        maxWidth='md'
        onClose={handleClose}
        aria-labelledby='responsive-dialog-title'
      >
        <DialogContent>
          <Box sx={{ mb: 2 }}>
            <Typography sx={{ mb: 2 }}> Create Invoice</Typography>
            <Divider />
          </Box>
          <CreatePaymentableItem
            handleClose={handleClose}
            setAllPayments={setAllPayments}
          />
        </DialogContent>
        <DialogActions>
          <Button autoFocus onClick={handleClose}>
            Close
          </Button>
        </DialogActions>
      </Dialog>
    </Card>
  );
};

export default PaymentableItemTable;
