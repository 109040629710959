import React, { useState, useEffect } from 'react';
import Select, { SelectChangeEvent } from '@mui/material/Select';
import {
  Grid,
  TextField,
  FormControl,
  MenuItem,
  InputLabel,
  Box,
  Button,
} from '@mui/material';
import { LoadingButton } from '@mui/lab';
import axios from 'axios';
import { url } from 'src/Helpers/utils/Constant';
import Toaster from 'src/Toaster/Toaster';

const UpdateTraineeDuplicateId = ({
  singleDuplicateIdCard,
  id,
  handelClose,
  setSingleDuplicateIdCard,
}: any) => {
  const [status, setStatus] = useState<string>('');
  const [note, setNote] = useState<string>('');
  const [isLoading, setIsLoading] = useState(false);

  const handelSubmit = async () => {
    setIsLoading(true);

    try {
      const { data } = await axios.put(
        `${url}/api/training/services/trainee/trainee-duplicateId/${id}`,
        {
          status,
          note,
        }
      );

      if (data.success) {
        setSingleDuplicateIdCard({
          ...singleDuplicateIdCard,
          status: status,
          note: note,
        });
        Toaster().fire({
          icon: 'success',
          title: data.message,
        });
        handelClose();
        setIsLoading(false);
      } else {
        setIsLoading(false);
      }
    } catch (err: any) {
      Toaster().fire({
        icon: 'error',
        title: err?.response?.data.message,
      });
      setIsLoading(false);
    }
  };
  return (
    <Box p={2}>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <FormControl fullWidth>
            <InputLabel id='demo-simple-select-label'>Status</InputLabel>
            <Select
              labelId='demo-simple-select-label'
              id='demo-simple-select'
              value={status}
              label='Status'
              onChange={(e) => setStatus(e.target.value)}
            >
              <MenuItem value={'pending'}>Pending</MenuItem>
              <MenuItem value={'approved'}>Approved</MenuItem>
              <MenuItem value={'rejected'}>Rejected</MenuItem>
            </Select>
          </FormControl>
        </Grid>

        <Grid item xs={12}>
          <TextField
            id='outlined-basic'
            label='Enter note'
            onChange={(e) => setNote(e.target.value)}
            variant='outlined'
            fullWidth
            multiline
            rows={4}
          />
        </Grid>
      </Grid>
      {!isLoading ? (
        <Button
          type='submit'
          fullWidth
          variant='contained'
          disableElevation
          onClick={handelSubmit}
          sx={{
            mt: 3,
            mb: 2,
            py: 1,
          }}
        >
          Submit
        </Button>
      ) : (
        <LoadingButton
          fullWidth
          variant='contained'
          disableElevation
          sx={{
            mt: 3,
            mb: 2,
            py: 1,
          }}
        >
          Updating...
        </LoadingButton>
      )}
    </Box>
  );
};

export default UpdateTraineeDuplicateId;
