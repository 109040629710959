import { useState } from "react";
import TextField from "@mui/material/TextField";
import Autocomplete from "@mui/material/Autocomplete";
type IProps = {
  label: string;
  required: boolean;
  options: any;
  getOptionLabel: any;
  setSearchAutoInputValue: (number) => void;
  searchAutoInputValue: number;
};

const AutoSearchInput = ({
  label,
  required,
  options,
  getOptionLabel,
  setSearchAutoInputValue,
  searchAutoInputValue: value,
}: IProps) => {
  const [inputValue, setInputValue] = useState("");
  return (
    <>
      <Autocomplete
        value={value}
        onChange={(_event: any, newValue: any) => {
          setSearchAutoInputValue(newValue?.id);
        }}
        inputValue={inputValue}
        onInputChange={(_event, newInputValue) => {
          setInputValue(newInputValue);
        }}
        id="controllable-states-demo"
        options={options}
        getOptionLabel={getOptionLabel}
        sx={{ width: "100%" }}
        renderInput={(params) => (
          <TextField {...params} label={label} required={required} />
        )}
      />
    </>
  );
};

export default AutoSearchInput;
