import moment from "moment";
import logo from "../../../../../assets/image/atab-logo.png";
import { Box, Typography, Grid } from "@mui/material";

const CommonPdfHeader = ({ pdfHead }: { pdfHead: string }) => {
  return (
    <>
      <Grid
        sx={{
          display: "flex",
          alignItems: "center",
          gap: 10,
          px: 2,
          mt: 3,

          position: "relative",
        }}
      >
        <Grid item md={6}>
          <img src={logo} alt="" height={55} width={220} />
        </Grid>
        <Grid item md={4}>
          <Typography variant="h3" fontWeight={"bold"} textAlign={"center"}>
            ATAB HEAD OFFICE
          </Typography>
          <Typography fontSize={12} fontWeight={"medium"}>
            Sattara Centre (15th Floor), 30/A, Nayapaltan, VIP Road, Dhaka-1000.
            <br />
            Tel : 9332728 , 8332712 , Email: atab@citech.net, www.atab.org.bd
          </Typography>
          <Typography
            fontSize={14}
            textAlign={"center"}
            pt={1.5}
            fontWeight={"bold"}
          >
            {pdfHead}
          </Typography>
        </Grid>
        <Typography
          fontSize={11}
          sx={{
            position: "absolute",
            top: 0,
            right: 0,
          }}
        >
          Date: {moment(new Date()).format("DD/MM/YYYY")}
        </Typography>
      </Grid>
    </>
  );
};

export default CommonPdfHeader;
