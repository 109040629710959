import { useState, useEffect, ReactNode, useRef } from "react";
import Box from "@mui/material/Box";
import axios from "axios";
import {
  Avatar,
  BottomNavigation,
  BottomNavigationAction,
  Card,
  CardContent,
  Container,
  Grid,
  Typography,
  Button,
  Tooltip,
  IconButton,
} from "@mui/material";
import { imgUrl, url } from "src/Helpers/utils/Constant";
import { IMemberInfo } from "src/Types/module/member/memberTypes";
import { useParams, useSearchParams } from "react-router-dom";
import { Helmet } from "react-helmet-async";
import GlobalLoader from "src/components/SuspenseLoader/GlobalLoader";
import InventoryTwoToneIcon from "@mui/icons-material/InventoryTwoTone";
import SuspenseLoader from "src/components/SuspenseLoader";
import BasicInformation from "./BasicInformation";
import CompanyInformation from "./CompanyInformation";
import CompanyDocumentInfo from "./CompanyDocumentInfo";
import TradeLicenseInformation from "./TradeLicenseInformation";
import BusinessLicenseInfo from "./BusinessLicenseInfo";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import DescriptionOutlinedIcon from "@mui/icons-material/DescriptionOutlined";
import BusinessCenterOutlinedIcon from "@mui/icons-material/BusinessCenterOutlined";
import CorporateFareOutlinedIcon from "@mui/icons-material/CorporateFareOutlined";
import OtherBusinessLicense from "./OtherBusinessLicense";
import CivilAviationInfo from "./CivilAviationInfo";
import CreateIcon from "@mui/icons-material/Create";
import ModalsWrapper from "src/components/Modals/ModalsWrapper";
import CreateCertificateModal from "./CreateCertificateModal";
import RepresentativeInfo from "./RepresentativeInfo";
import ProposerInformation from "./ProposerInformation";
import CompanyOwnerInfo from "./CompanyOwnerInfo";
import CompanyInvoices from "./CompanyInvoices";
import PrintIcon from "@mui/icons-material/Print";
import useTheme from "@mui/material/styles/useTheme";
import { useReactToPrint } from "react-to-print";
import PrintInformationForm from "./PrintInformationForm/PrintInformationForm";
import OldDocuments from "./OldDocuments";
import { useAuthContext } from "src/components/Context/AuthContext/AuthContext";
import { FcInspection } from "react-icons/fc";
import Inspection from "./inspection/Inspection";
import MemberCertificates from "./certificates/MemberCertificates";

const CompanyInfoDetails = () => {
  const { memberId } = useParams();
  const { user } = useAuthContext();
  const [companyInfo, setCompanyInfo] = useState<IMemberInfo>(
    {} as IMemberInfo
  );
  let [searchParams, setSearchParams] = useSearchParams({ tabvalue: "" });

  const theme = useTheme();
  const componentRef = useRef(null);
  const handlePrint = useReactToPrint({
    content: () => componentRef.current,
    documentTitle: `ATAB_${companyInfo.user_member_company_name}_${companyInfo.user_member_id}`,
  });

  const tabValue = searchParams.get("tabvalue") || "basic";

  const [loading, setLoading] = useState<boolean>(false);
  const [refresh, setRefresh] = useState<boolean>(false);
  const [open, setOpen] = useState(false);
  // const [downloadCertificate, setDownloadCertificate] = useState([]);

  useEffect(() => {
    const CancelToken = axios.CancelToken;
    const source = CancelToken.source();
    setLoading(true);
    if (memberId) {
      axios
        .get(`${url}/api/atab/user-member/get/a/member/for-admin/${memberId}`, {
          cancelToken: source.token,
        })
        .then((data) => {
          setCompanyInfo(data.data.data);
          setLoading(false);
        })
        .catch((err) => {
          if (axios.isCancel(err)) {
            setLoading(false);
          } else {
            // handle error
            setLoading(false);
          }
        });
    }
    return () => {
      // cancel the request before component unmounts
      source.cancel();
    };
  }, [memberId, refresh]);

  // useEffect(() => {
  //   (async function () {
  //     const { data } = await axios.get(
  //       `${url}/api/atab/user-member/certificate/${memberId}?user=admin`
  //     );
  //     if (data.success) {
  //       setDownloadCertificate(data.data);
  //     }
  //   })();
  // }, [memberId, refresh]);

  let content: ReactNode;

  if (loading) {
    content = <SuspenseLoader />;
  }

  if (tabValue === "basic" && companyInfo) {
    content = (
      <BasicInformation setRefresh={setRefresh} memberInfo={companyInfo} />
    );
  }
  if (tabValue === "inspection") {
    content = (
      <Inspection forwarderId={companyInfo?.application_forwarder_id} />
    );
  }
  if (tabValue === "companyInfo") {
    content = <CompanyInformation setRefresh={setRefresh} />;
  }
  if (tabValue === "ownerInfo") {
    content = <CompanyOwnerInfo />;
  }
  if (tabValue === "representative") {
    content = <RepresentativeInfo />;
  }
  if (tabValue === "document") {
    content = <CompanyDocumentInfo />;
  }
  if (tabValue === "tradeLicense") {
    content = <TradeLicenseInformation />;
  }
  if (tabValue === "business") {
    content = <BusinessLicenseInfo />;
  }
  if (tabValue === "association") {
    content = <OtherBusinessLicense />;
  }

  if (tabValue === "civilAviation") {
    content = <CivilAviationInfo />;
  }

  if (tabValue === "proposer") {
    content = <ProposerInformation />;
  }
  if (tabValue === "transaction") {
    content = (
      <CompanyInvoices companyName={companyInfo?.user_member_company_name} />
    );
  }
  if (tabValue === "documents") {
    content = <OldDocuments />;
  }
  if (tabValue === "certificates") {
    content = <MemberCertificates />;
  }

  return (
    <>
      {!loading ? (
        <>
          <Helmet>
            <title>Atab - Company info</title>
          </Helmet>
          <Box>
            <Box mt={3}>
              <Container sx={{ mb: 2 }} maxWidth="xl">
                <Typography variant="h2" component="h2" gutterBottom>
                  {companyInfo?.user_member_company_name}
                </Typography>
                <Typography variant="h6" pt={1}>
                  {`ID: ${companyInfo?.user_member_id_card_number}`}
                </Typography>
                <Grid
                  sx={{
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                    gap: 1,
                  }}
                >  
                  <Grid item sx={{ my: 2 }}>
                    <Grid
                      sx={{
                        display: "flex",
                        flexWrap: "wrap",
                        alignItems: "center",
                        gap: 2,
                      }}
                    >
                      {/* <Grid item xs={12}>
                        <Typography fontWeight={600} fontSize={14}>
                          User ID:{companyInfo?.user_member_id}
                        </Typography>
                      </Grid> */}

                      {user?.user_admin_role === "Super Admin" &&
                      companyInfo.user_member_account_status === "active" ? (
                        <Grid
                          item
                          xs={12}
                          sx={{ display: "flex", flexWrap: "wrap" }}
                        >
                          <Button
                            size="small"
                            variant="contained"
                            sx={{
                              mt: 1,
                              mr: 2,
                              fontSize: 12,
                              fontWeight: 400,
                              lineHeight: "15px",
                              textAlign: "left",
                            }}
                            onClick={() => setOpen(true)}
                            startIcon={<CreateIcon />}
                          >
                            Create <br />
                            Certificate
                          </Button>
                          {/* <Box>
                            {downloadCertificate?.map((sCertificate) => (
                              <Certificate
                                key={sCertificate.c_id}
                                sCertificate={sCertificate}
                                setRefresh={setRefresh}
                              />
                            ))}
                          </Box> */}
                        </Grid>
                      ) : (
                        ""
                      )}
                      {/* {companyInfo.is_applied ? ( */}
                      <Tooltip title="Print information form">
                        <IconButton onClick={handlePrint}>
                          <PrintIcon
                            sx={{
                              cursor: "pointer",
                              color: theme.colors.primary.main,
                            }}
                          />
                        </IconButton>
                      </Tooltip>
                      {/* ) : (
                        ""
                      )} */}
                    </Grid>
                  </Grid>

                  <Grid item>
                    {companyInfo?.company_owner_photo ? (
                      <img
                        style={{
                          width: "100px",
                          height: "100px",
                          borderRadius: "50%",
                          border: "5px solid #dedede",
                        }}
                        src={`${imgUrl}/${companyInfo?.company_owner_photo}`}
                      />
                    ) : (
                      <Avatar />
                    )}
                  </Grid>
                </Grid>
              </Container>

              <Container maxWidth="xl" sx={{ pb: 6 }}>
                <Card>
                  <CardContent>
                    <BottomNavigation
                      sx={{
                        py: 5,
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "space-evenly",
                        flexWrap: "wrap",
                        height: "auto",
                        // boxShadow:
                        //   "0px 2px 4px -3px rgba(34, 51, 84, 0.1), 0px 5px 12px -4px rgba(34, 51, 84, 0.05)",
                      }}
                      showLabels
                      value={tabValue}
                      onChange={(_event, newValue) => {
                        setSearchParams({
                          tabvalue: newValue,
                        });
                      }}
                    >
                      <BottomNavigationAction
                        value="basic"
                        label="Basic "
                        icon={<InfoOutlinedIcon />}
                      />
                      <BottomNavigationAction
                        value="inspection"
                        label="Inspection"
                        icon={<FcInspection size={22} />}
                      />
                      <BottomNavigationAction
                        value="companyInfo"
                        label="Company "
                        icon={<DescriptionOutlinedIcon />}
                      />
                      <BottomNavigationAction
                        value="ownerInfo"
                        label="Owner "
                        icon={<DescriptionOutlinedIcon />}
                      />
                      {/* <BottomNavigationAction
                        value="representative"
                        label="Representative "
                        icon={<DescriptionOutlinedIcon />}
                      /> */}
                      <BottomNavigationAction
                        value="document"
                        label=" Document"
                        icon={<CorporateFareOutlinedIcon />}
                      />
                      <BottomNavigationAction
                        value="tradeLicense"
                        label="Trade License "
                        icon={<BusinessCenterOutlinedIcon />}
                      />
                      <BottomNavigationAction
                        value="business"
                        label="Business license "
                        icon={<DescriptionOutlinedIcon />}
                      />
                      <BottomNavigationAction
                        value="association"
                        label="Other Business License"
                        icon={<InventoryTwoToneIcon />}
                      />
                      <BottomNavigationAction
                        value="civilAviation"
                        label="Civil aviation "
                        icon={<DescriptionOutlinedIcon />}
                      />
                      <BottomNavigationAction
                        value="proposer"
                        label="Proposer "
                        icon={<InfoOutlinedIcon />}
                      />
                      <BottomNavigationAction
                        value="transaction"
                        label="Money receipt"
                        icon={<InfoOutlinedIcon />}
                      />
                      <BottomNavigationAction
                        value="certificates"
                        label="Certificate's"
                        icon={<InfoOutlinedIcon />}
                      />
                      <BottomNavigationAction
                        value="documents"
                        label="Old Documents"
                        icon={<InfoOutlinedIcon />}
                      />
                    </BottomNavigation>
                    {content}
                  </CardContent>
                </Card>
              </Container>
            </Box>
          </Box>
        </>
      ) : (
        <GlobalLoader />
      )}
      <ModalsWrapper
        setShowModal={setOpen}
        showModal={open}
        modalData={{ title: "Create member certificate" }}
      >
        <CreateCertificateModal setOpen={setOpen} setRefresh={setRefresh} />
      </ModalsWrapper>

      <Box display={"none"}>
        <PrintInformationForm componentRef={componentRef} memberId={memberId} />
      </Box>
    </>
  );
};

export default CompanyInfoDetails;
