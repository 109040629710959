import {
  Container,
  Card,
  CardHeader,
  TableContainer,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
} from "@mui/material";

import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { HttpClient } from "src/Helpers/http/http";
import { TableLoader, TableNotFound } from "src/Utils/TableLoader";
import ShowTraineeIdCard from "./ShowTraineeIdCard";
export type TIdCard = {
  batch_id: number;
  batch_no: string;
  id_photo: string;
  created_at: string;
  created_by: number;
  id: number;
  trainee_id: number;
  training_batch_name: string;
  training_course_application_name: string;
  valid_date: string;
};

const IdCardList = () => {
  const { id } = useParams();
  const [cardList, setCardList] = useState<TIdCard[]>([]);
  const [loading, setLoading] = useState(false);
  const [refresh, setRefresh] = useState(false);

  useEffect(() => {
    (async () => {
      try {
        setLoading(true);
        const data: any = await HttpClient.get(
          `/api/trainee/id-card?batch_id=${id}`
        );

        if (data.success) {
          setCardList(data.data);
          setLoading(false);
        } else {
          setLoading(false);
        }
      } catch (err) {
        setLoading(false);
      }
    })();
  }, [id, refresh]);

  return (
    <div>
      <Container sx={{ mt: 4 }} maxWidth="xl">
        <Card>
          <CardHeader title="ID card list" />

          <TableContainer>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell>SL No</TableCell>
                  <TableCell>Trainee Name</TableCell>
                  <TableCell>validity date</TableCell>
                  <TableCell>View</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {loading ? (
                  <TableLoader />
                ) : cardList?.length ? (
                  <>
                    {cardList?.map((trainee, index: number) => (
                      <ShowTraineeIdCard
                        key={index}
                        trainee={trainee}
                        index={index}
                        setRefresh={setRefresh}
                      />
                    ))}
                  </>
                ) : (
                  <TableNotFound />
                )}
              </TableBody>
            </Table>
          </TableContainer>
        </Card>
      </Container>
    </div>
  );
};

export default IdCardList;
